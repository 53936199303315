import React, {useEffect, useState} from "react";

import styles from './CustomerInfoForm.module.css'

import {Field, FieldArray, formValueSelector, InjectedFormProps, reduxForm} from "redux-form";
import {SimpleInput} from "../../inputs";
import defaultStyles from "../../../screens/Cabinet/screens/defaultPage.module.css";
import {SelectorInput} from "../../inputs/inputSelector";
import {GREEN_CLR} from "../../../constants";
import {required} from "../validators";
import {connect} from "react-redux";
import MultiJobInput from "../../inputs/MultiJobInput";
import {Button} from "../../uiKit/btns/Button";
import stylesLight from "../../../stylesLight.module.css";
import {AppState} from "../../../redux/store";
import {CustomerJobType, CustomerType, UserType} from "../../../types/UserTypes";
import {Loader} from "../../uiKit/Loader";



const statusSelectorOptions = [
    {
        label: "Телефон",
        value: 1,
    },
    {
        label: "Telegram",
        value: 2,
    },
    {
        label: "Почта",
        value: 3,
    },
    {
        label: "ВКонтакте",
        value: 4,
    }
];

type JobType = CustomerJobType & {
    isNew?: boolean
}
type CustomerInfoType = {
    contactWayForStudent?: number,
    contactWayForStudentValue?: string,
    contactWayForTeacher: number,
    contactWayForTeacherValue: string,
    jobs: Array<JobType>
}

function CustomerInformationPreRedux(props: InjectedFormProps<CustomerInfoType, MapStateToPropsType> & MapStateToPropsType) {
    const [isFirst, setIsFirst] = useState(true);
    // const [isLoading, setIsFirst] = useState(true);
    // const [studentPlaceHolder, setStudentPlaceHolder] = useState<string>();
    // const [teacherPlaceHolder, setTeacherPlaceHolder] = useState<string>();
    const {customer, initialize, user} = props
    useEffect(() => {
        if (customer && isFirst) {
            console.log("useEffect", initialize)
            initialize({
                        ...customer
                    }
            )

            setIsFirst(false)
        }

    }, [customer, initialize, isFirst])

    function getInitialValue(d: number): string | undefined {
        switch (d) {
            case 2:
                return user?.telegram
            case 4:
                return user?.vk
            case 3:
                return user?.email
            default:
                return undefined
        }
    }

    function getPlaceHolder(d: number | undefined): string {
        switch (d) {
            case 1:
                return 'Укажите номер телефона'
            case 2:
                return 'Укажите ваш аккаунт Telegram (вида @пользователь)'
            case 3:
                return 'Укажите вашу электронную почту'
            case 4:
                return 'Укажите ссылку на ваш аккаунт ВКонтакте'
            default:
                return 'Укажите контактные данные'
        }
    }

    // function changePlaceHolder(d: number): void {
    //     setStudentPlaceHolder(getPlaceHolder(d));
    // }


    if (customer && isFirst)
        return <Loader/>
    console.log(customer, props.valuesInit)
    return (
            <form onSubmit={props.handleSubmit} className={styles.formContainer}>
                <div>
                    <div className={defaultStyles.title + " " + stylesLight.subtitle}>
                        Анкета заказчика
                    </div>
                    <div className={stylesLight.doit}>
                        <FieldArray name="jobs" component={MultiJobInput}
                                    placeholderForAddBtn={'Добавить информацию о работе'} {...{ name_suffix: 'name' }}/>
                    </div>
                    <div className={defaultStyles.title + " " + stylesLight.subtitle} style={{marginTop: "1rem"}}>
                        Способы связи
                    </div>
                    <div className={styles.subTitle2 + " " + stylesLight.doit}>
                        Выберете предпочтительный способ связи с преподавателем дисциплины “Основы проектной
                        деятельности”
                        (он НЕ будет виден студентам):
                    </div>
                    <Field name="contactWayForTeacher" type="text" defaultItem={0} component={SelectorInput}
                           options={statusSelectorOptions} defaultColor={GREEN_CLR} validate={[required]}
                           customOnChange={(d: number): void => {
                               const value = getInitialValue(d)

                               initialize({
                                   ...props.valuesInit,
                                   contactWayForTeacher: d,
                                   contactWayForTeacherValue: value
                               })

                           }}
                    />
                    <Field type="text" component={SimpleInput} name='contactWayForTeacherValue'
                           label={getPlaceHolder(props.valuesInit.contactWayForTeacher)} validate={[required]}/>
                    <div className={styles.subTitle2 + " " + stylesLight.doit}>
                        Если вы хотите общаться со студентами напрямую, то выберете удобный способ связи (он будет виден
                        только студентам из вашего проекта):
                    </div>
                    <Field name="contactWayForStudent" type="text" defaultItem={0} component={SelectorInput}
                           options={statusSelectorOptions} defaultColor={GREEN_CLR} validate={[required]}
                           customOnChange={(d: number): void => {
                               const value = getInitialValue(d)

                               initialize({
                                   ...props.valuesInit,
                                   contactWayForStudent: d,
                                   contactWayForStudentValue: value
                               })
                           }}
                    />
                    <Field type="text" component={SimpleInput} name='contactWayForStudentValue'
                           label={getPlaceHolder(props.valuesInit.contactWayForStudent)}/>
                    <div className={styles.div}>
                        {/*<Link to={'/cabinet/customer/submit_project'}>*/}
                        <Button type="submit" className={styles.btn + " " + stylesLight.button_text}>
                            Сохранить и продолжить
                        </Button>
                        {/*</Link>*/}
                    </div>
                </div>
            </form>
    )
}

type MapStateToPropsType = {
    customer?: CustomerType
    user?: UserType
    valuesInit: CustomerInfoType
}
const form = reduxForm<CustomerInfoType, MapStateToPropsType>({form: 'customers-information'})(CustomerInformationPreRedux)
const selector = formValueSelector('customers-information')
export const CustomerInformationForm = connect((state: AppState): MapStateToPropsType => ({
    customer: state.user?.user?.customer,
    user: state.user.user,
    valuesInit: {
        contactWayForStudent: selector(state, 'contactWayForStudent'),
        contactWayForStudentValue: selector(state, 'contactWayForStudentValue'),
        contactWayForTeacher: selector(state, 'contactWayForTeacher'),
        contactWayForTeacherValue: selector(state, 'contactWayForTeacherValue'),
        jobs: selector(state, 'jobs'),
    }

}))(form)
