import styles from "./ProjectList.module.css";
import {ProjectItem} from "screens/Projects/ProjectItem";
import React, {useEffect, useState} from "react";
import {Loader} from "../uiKit/Loader";
import PropTypes from 'prop-types';
import {api_connector} from "../../redux/api/UserApi";
import {TagsBlock} from "../uiKit/tags/TagsBlock";
import {ProjectsListType} from "../../types/ProjectTypes";

type PropsType = {
    status: 2 | 5 | undefined
}

export function ProjectsList(props: PropsType) {
    const {status = undefined} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setError] = useState(false);
    const [items, setItems] = useState<ProjectsListType>([])
    const [selectedTags, setSelectedTags] = useState<Array<number>>([])
    useEffect(() => {
        api_connector.getProjects(status, selectedTags).then(d => {
            setItems(d);
            setError(false);
            setIsLoading(false);
        }).catch((e) => {
            setItems([]);
            setError(true);
            setIsLoading(false);
            console.error(e)
        })
    }, [status, selectedTags])

    if (isLoading && !hasError) {
        return <Loader/>
    }
    return (
            <div>
                <TagsBlock selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
                <div className={styles.content}>
                    {items && items.map(i => (
                            <ProjectItem key={`Project_${i.id}`} id={i.id} name={i.name} description={i.description}
                                         tags={i.tags} opened={i.is_recruiting}/>
                    ))}

                    {/*<div className={styles.menu}>*/}
                    {/*    <TagSearcher tags={this.state.tags}/>*/}
                    {/*</div>*/}
                </div>
            </div>
    )
}

ProjectsList.propTypes = {
    status: PropTypes.number
}

ProjectsList.defaultProps = {
    status: undefined
}
