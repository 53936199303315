import { ChangeEventHandler, FC, memo, useCallback, useMemo, useState } from "react";
import { Input } from "@material-ui/core";

import css from "./styles.module.css";
import { ListItem } from "./ListItem";
import { withChangeLog } from "./utils";

interface Props {
    data: ProjectHistoryItem[];
    projectId: number;
}
export const HistoryList: FC<Props> = memo(({ data, projectId }) => {
    const [search, setSearch] = useState("");

    const itemsWithChangeLog = useMemo(() => withChangeLog(data), [data]);

    const filteredItems = useMemo(() => {
        if (!search) return itemsWithChangeLog;

        const searchString = search.trim().toLowerCase();

        return itemsWithChangeLog.filter((item) => item.changelog.toLowerCase().includes(searchString));
    }, [itemsWithChangeLog, search]);

    const onChangeSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => setSearch(e.target.value),
        [setSearch]
    );

    return (
        <div className={css.modalContent}>
            <div className={css.searchContainer}>
                <Input placeholder="Поиск" onChange={onChangeSearch} />
            </div>
            <div className={css.historyItems}>
                {filteredItems.map((item) => (
                    <ListItem key={item.history_id} {...item} projectId={projectId} />
                ))}
                {filteredItems.length === 0 && <p>Ничего не найдено :(</p>}
            </div>
        </div>
    );
});
