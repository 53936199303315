import React from "react";

export function Iform() {

    return <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.115 9.23499H2.885C1.295 9.23499 0 10.53 0 12.12V14.01C0 14.29 0.225 14.51 0.5 14.51H8.5C8.775 14.51 9 14.29 9 14.01V12.12C9 10.53 7.705 9.23499 6.115 9.23499Z"
            fill="white"/>
        <path
            d="M1.90527 5.9501C1.90527 7.38511 3.07027 8.54511 4.50027 8.54511C5.93027 8.54511 7.09527 7.38511 7.09527 5.9501C7.09527 4.5201 5.93027 3.3551 4.50027 3.3551C3.07027 3.3551 1.90527 4.5151 1.90527 5.9501Z"
            fill="white"/>
        <path
            d="M17.9053 5.9501C17.9053 7.38511 19.0703 8.54511 20.5003 8.54511C21.9303 8.54511 23.0953 7.38511 23.0953 5.9501C23.0953 4.5201 21.9303 3.3551 20.5003 3.3551C19.0703 3.3551 17.9053 4.5151 17.9053 5.9501Z"
            fill="white"/>
        <path
            d="M22.115 9.23499H18.885C17.295 9.23499 16 10.53 16 12.12V14.01C16 14.29 16.225 14.51 16.5 14.51H24.5C24.775 14.51 25 14.29 25 14.01V12.12C25 10.53 23.705 9.23499 22.115 9.23499Z"
            fill="white"/>
        <path
            d="M9 3.355C9 5.2 10.5 6.705 12.35 6.705C14.195 6.705 15.7 5.205 15.7 3.355C15.7 1.505 14.2 0 12.35 0C10.505 0.005 9 1.505 9 3.355Z"
            fill="white"/>
        <path
            d="M14.9997 12.12C14.9997 10.62 15.8597 9.31501 17.1097 8.66501C16.4447 8.09001 15.5797 7.755 14.6746 7.755H10.3296C9.41965 7.755 8.55465 8.09001 7.88965 8.67001C9.13965 9.31501 9.99965 10.62 9.99965 12.12V14.01C9.99965 14.19 9.96965 14.355 9.90965 14.515H15.0897C15.0297 14.355 14.9997 14.19 14.9997 14.01V12.12Z"
            fill="white"/>
    </svg>
}
