import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import { Loader } from "../../components/uiKit/Loader";
import { AppState } from "../../redux/store";
import { useProject } from "./hooks";
import { StudentProjectView } from "./StudentProjectView";

type ParamsType = {
    projectId: string;
};

const selector = (state: AppState) => ({
    user: state.user,
    statuses: state.user.user?.statuses || [],
});

const StudentProject: FC = () => {
    const { projectId } = useParams<ParamsType>();
    const { user, statuses } = useSelector(selector);
    const { loading, error, project, refetch } = useProject(projectId);

    if (loading) {
        return <Loader />;
    }

    if (error || !project) {
        return <Redirect to="/" />;
    }

    return <StudentProjectView user={user} statuses={statuses} project={project} refetch={refetch} />;
};

export default StudentProject;
