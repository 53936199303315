import React from "react";
import styles from "./input.module.css"
import stylesLight from "../../stylesLight.module.css";


export function InputTextArea(props) {
    const {disabled = false, input, label, meta: {touched, error}} = props;
    // const [value, setValue] = useState(defaultValue?.toString() || input.value || "");

    return (
        <div>
            <textarea {...input} onChange={(v) => {
                // setValue(v.value);
                input.onChange(v);
            }} placeholder={label} value={input.value} disabled={disabled}
                      className={styles.simple + " " + stylesLight.doit + " " + (touched && error ? styles.error : "")}
                      style={props.stylecomp && {marginTop: "0.53rem"}}/>
            {touched && ((error && <span className={styles.errorMessage}>{Array.isArray(error) ? error.map(s =>
                <React.Fragment>{s}<br/></React.Fragment>) : error}</span>))}
        </div>
    )
}
