import React, {useEffect} from "react";
import styles from "./Cabinet.module.css"
import {CabinetBar} from "./CabinetBar";
import {Route, Switch} from "react-router-dom";
import {PersonalInformation} from "./screens/PersonalInformation";
import {AuthRoute} from "../../features/AuthRoute";
import {CustomerInfo} from "./screens/Customer/CustomerInfo";
import {SubmitProject} from "./screens/Customer/SubmitProject";
import {MyProjectsCustomer} from "./screens/Customer/MyProjectsCustomer";
import {getMeThunk} from "../../redux/reducers/userReducer";
import {connect, useDispatch} from "react-redux";
import {MyProjectsStudent} from "./screens/Student/MyProjectsStudent";
import {StudentInfo} from "./screens/Student/InfoStudent";
import {TeacherCabinet} from "./screens/Teacher/TeacherCabinet"

function CabinetPre(props) {
    const dispatch = useDispatch();
    const isLoading = props.isLoading
    useEffect(() => {
        dispatch(getMeThunk())
    }, [dispatch, props.isLoggedIn, isLoading])
    if (isLoading) {
        return (
            <div/>
        )
    }
    return (
        <div className={styles.Cabinet}>
            <div className={styles.mainBar}>
                <CabinetBar />
            </div>
            {/*<div className={styles.mobileBar}>*/}
            {/*    <LeftBar/>*/}
            {/*</div>*/}

            <div className={styles.Content}>
                <Switch>
                    <Route exact path={'/cabinet'} component={PersonalInformation}/>
                    <AuthRoute exact path='/cabinet/customer/info' type="private" component={CustomerInfo}/>
                    <AuthRoute exact path='/cabinet/customer/submit_project' type="private" component={SubmitProject}/>
                    <AuthRoute exact path='/cabinet/customer/projects' type="private" component={MyProjectsCustomer}/>
                    <AuthRoute exact path='/cabinet/student/info' type="private" component={StudentInfo}/>
                    <AuthRoute exact path='/cabinet/student/projects' type="private" component={MyProjectsStudent}/>
                    <AuthRoute exact path='/cabinet/teacher/groups' type="private" component={TeacherCabinet}/>
                </Switch>
            </div>
        </div>
    )
}

export const Cabinet = connect((s) => ({
    isLoading: !s.user.user,
    isLoggedIn: s.user.isLoggedIn
}))(CabinetPre);
