declare global {
    interface String {
        trim(): string;

        capitalize(): string;
    }
}
// eslint-disable-next-line no-extend-native
String.prototype.trim = function () {
    return String(this).replace(/^\s+|\s+$/g, '');
};


// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

export {}
