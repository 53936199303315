import React from "react";
import styles from "./SubmitProjectForm.module.css"
import {Field, FieldArray, InjectedFormProps, reduxForm} from "redux-form";
import {MultiInputSimple, OwnPropsType} from "../../inputs/MultiInputSimple";
import {SimpleInput} from "../../inputs";
import {Button} from "../../uiKit/btns/Button";
import stylesLight from "../../../stylesLight.module.css"
import {OptionType} from "../../../types/ReactSelectTypes";


export type SubmitProjectSecondFieldsType = {
    requirements?: Array<OptionType>
    min_members?: number | string
    max_members?: number | string
    tutors?: Array<OptionType>
}

function SubmitProjectSecondPreRedux(props: InjectedFormProps<SubmitProjectSecondFieldsType>) {
    return (
            <form onSubmit={props.handleSubmit} className={styles.formContainer}>
                <div className={styles.title + " " + stylesLight.subtitle}>
                    О команде проекта
                </div>
                <div className={styles.itemsContainer}>

                    <div className={styles.subTitle + " " + stylesLight.doit}>
                        Требования к участникам:
                    </div>
                    <div className={stylesLight.doit}>
                        <FieldArray<OwnPropsType, OptionType> name="requirements" component={MultiInputSimple}
                                                              name_suffix={"name"}
                                                              isTutor={false}
                                                              label='Например, необходим опыт работы с Python'
                                                              placeholderForAddBtn={'Добавить требование к участникам'}/>
                    </div>
                    <div className={styles.subTitle + " " + stylesLight.doit} style={{marginTop: "1rem"}}>
                        Минимальное количество участников в проекте:
                    </div>
                    <Field type="text" component={SimpleInput} name='min_members'
                           label='Минимальное количество участников'
                           stylecomp={true}/>
                    <div className={styles.subTitle + " " + stylesLight.doit} style={{marginTop: "1rem"}}>
                        Максимальное количество участников в проекте:
                    </div>
                    <Field type="text" component={SimpleInput} name='max_members'
                           label='Максимальное количество участников'
                           stylecomp={true}/>
                    <div className={styles.subTitle + " " + stylesLight.doit}>
                        Если у вас уже определен куратор, то прикрепите его к вашему проекту.
                        Куратор должен предварительно зарегистрироваться на сайте.
                    </div>
                    <div className={stylesLight.doit}>
                        <FieldArray<OwnPropsType, OptionType> name="tutors" component={MultiInputSimple}
                                                              name_suffix={"tutors"}
                                                              isTutor={true}
                                                              label='Начните вводить фамилию куратора'
                                                              placeholderForAddBtn={'Добавить куратора проекта'}
                                                              internal_type={true}/>
                    </div>
                </div>

                <Button type="submit" className={styles.btn}>
                    <div className={stylesLight.button_text}> Далее</div>
                </Button>
            </form>
    )
}

export const SubmitProjectSecondForm = reduxForm<SubmitProjectSecondFieldsType>({
    form: 'customer-submit-project',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(SubmitProjectSecondPreRedux)
