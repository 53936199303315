import React from "react";
import {Button} from "./Button";
import {useHistory} from "react-router-dom";

type PropsType = {
    className?: string
    to: string
}
export const ButtonLink: React.FC<PropsType> = (props) => {
    const history = useHistory()
    return (
            <Button className={props.className} onClick={() => history.push(props.to)}>{props.children}</Button>
    )
}
