import React from "react";
import styles from "./About.module.css";
import icon from "../../icons/one.svg";
import {useHistory} from "react-router-dom";
import Box from '@mui/material/Box';
import icon_2 from "../../icons/two.svg";
import icon_1 from "../../icons/exl.svg";
import {CustomLink} from "../../components/uiKit/links/CustomLink";
import stylesLight from "../../stylesLight.module.css";


export function About() {
    const history = useHistory();
    return (
        <div className={styles.cont}>
            <div className={stylesLight.title}>
                О нас
            </div>
            <div className={styles.border + " " + stylesLight.text}>
                Здравствуйте, дорогие друзья!
                <br/>
                <br/>
                Вас приветствует команда сотрудников, студентов и друзей Новосибирского государственного университета.
                Своей целью мы видим развитие проектной культуры в НГУ, Академгородке и за его пределами. На наш взгляд
                реализация проектов стала неотъемлемым элементом жизнедеятельности современного человека.
                <br/>
                Компетенции в области разработки и реализации проектов, командной работы и коммуникации начинают
                формироваться еще в школе и развиваются на протяжении всей нашей жизни.
                <br/>
                Мы придерживаемся позиции, что любой материал лучше усваивается, если он закрепляется конкретной
                деятельностью. Пирамида эффективности обучения свидетельствует о том, что максимальный процент усвоения
                материала достигается при применении его на практике — через «делание» (75%) и немедленное применение
                знаний
                (80%).
                <br/>
                Есть достаточное количество книг, онлайн-курсов и других материалов, описывающих то, как именно
                необходимо инициировать, планировать и реализовывать проекты. С частью из них вы можете ознакомиться в
                разделе <CustomLink link={'/reference_information'}>«Справочная информация»</CustomLink>. Изучение
                данных источников, безусловно, является полезным. Но ни одна книга не заменит собственного
                деятельностного опыта.
                <br/>
                Реализация студенческого проекта — это уникальная возможность попробовать себя в широком спектре
                направлений деятельности и развить свои компетенции в относительно безопасной студенческой среде.
                <br/>
                Надеемся, что материалы нашего сайта помогут студентам определиться с тематикой проектов, а также
                успешно их реализовать!
                <br/>
            </div>
            <div className={stylesLight.subtitle}>
                На нашем сайте вы можете:
            </div>
            <div style={{display: "flex", flexDirection: "row", marginTop: "2rem"}}>
                <div>
                    <img src={icon} alt={'1'}/>
                </div>
                <div className={stylesLight.text} style={{paddingLeft: "0.7rem"}}>
                    Ознакомиться с проектами, реализуемыми студентами и сотрудниками Новосибирского государственного
                    университета, а также представителями компаний-партнеров НГУ.
                </div>
            </div>
            <div className={styles.buttons}>
                <Box sx={{flexGrow: 1}}>
                    {/*<Grid container spacing={3}>*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        <button onClick={() => history.push('/')} className={styles.small_btn}>*/}
                    {/*            Проекты*/}
                    {/*        </button>*/}
                    {/*        <div className={styles.small + " " + stylesLight.small}>*/}
                    {/*            В разделе отображаются прошедшие модерирование проектные заявки.*/}
                    {/*        </div>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        <button onClick={() => history.push('/bezdna')} className={styles.small_btn}>*/}
                    {/*            Бездна*/}
                    {/*        </button>*/}
                    {/*        <div className={styles.small + " " + stylesLight.small}>*/}
                    {/*            Здесь можно найти проекты, находящиеся на стадии модерации или доработки.*/}
                    {/*        </div>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        <button onClick={() => history.push('/archive')} className={styles.small_btn}>*/}
                    {/*            Архив*/}
                    {/*        </button>*/}
                    {/*        <div className={styles.small + " " + stylesLight.small}>*/}
                    {/*            В разделе находятся проекты прошлых лет.*/}
                    {/*        </div>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <div className={styles.btnGrid}>
                        <div className={styles.btnItem}>
                            <button onClick={() => history.push('/')} className={styles.small_btn}>
                                Проекты
                            </button>
                            <div className={styles.small + " " + stylesLight.small}>
                                В разделе отображаются прошедшие модерирование проектные заявки.
                            </div>
                        </div>
                        <div className={styles.btnItem}>
                            <button onClick={() => history.push('/bezdna')} className={styles.small_btn}>
                                Бездна
                            </button>
                            <div className={styles.small + " " + stylesLight.small}>
                                Здесь можно найти проекты, находящиеся на стадии модерации или доработки.
                            </div>
                        </div>
                        <div className={styles.btnItem}>
                            <button onClick={() => history.push('/archive')} className={styles.small_btn}>
                                Архив
                            </button>
                            <div className={styles.small + " " + stylesLight.small}>
                                В разделе находятся проекты прошлых лет.
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginTop: "2rem"}}>
                <div><img src={icon_2} alt={'2'}/></div>
                <div className={stylesLight.text} style={{paddingLeft: "0.7rem", marginTop: "0.7rem"}}>
                    Стать заказчиком студенческого проекта.
                </div>
            </div>
            <div className={styles.buttons}>
                <Box sx={{flexGrow: 1}}>
                    {/*<Grid container spacing={2}>*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        <button onClick={() => history.push('/for_customers')} className={styles.small_btn}>*/}
                    {/*            Заказчику*/}
                    {/*        </button>*/}
                    {/*        <div className={styles.small + " " + stylesLight.small}>*/}
                    {/*            Подробная информация для потенциальных заказчиков.*/}
                    {/*        </div>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={8}>*/}
                    {/*        <div style={{display: "flex", flexDirection: "row"}}>*/}
                    {/*            <div>*/}
                    {/*                <img src={icon_1} alt={'!'} style={{flex: 1, marginTop: "1.7rem"}}/>*/}
                    {/*            </div>*/}
                    {/*            <div className={styles.exc + " " + stylesLight.text} style={{paddingLeft: "0.7rem"}}>*/}
                    {/*                Ввиду того, что основная целевая аудитория сайта – студенты, мы обязаны предупредить*/}
                    {/*                заказчиков, что не несем ответственности за реализацию студентами того или иного*/}
                    {/*                проекта, однако будем стараться поддерживать и методически сопровождать движение*/}
                    {/*                проектных команд.*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <div className={styles.customerGrid}>
                        <div>
                            <button onClick={() => history.push('/for_customers')} className={styles.small_btn}>
                                Заказчику
                            </button>
                            <div className={styles.small + " " + stylesLight.small}>
                                Подробная информация для потенциальных заказчиков.
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div>
                                <img src={icon_1} alt={'!'} style={{flex: 1, marginTop: "1.7rem"}}/>
                            </div>
                            <div className={styles.exc + " " + stylesLight.text} style={{paddingLeft: "0.7rem"}}>
                                Ввиду того, что основная целевая аудитория сайта – студенты, мы обязаны предупредить
                                заказчиков, что не несем ответственности за реализацию студентами того или иного
                                проекта, однако будем стараться поддерживать и методически сопровождать движение
                                проектных команд.
                            </div>
                        </div>
                    </div>
                </Box>
            </div>

        </div>
    )
}
