import React from "react";
import styles from "./ForCustomers.module.css";
import icon from "../../icons/exl.svg";
import stylesLight from "../../stylesLight.module.css";
import {CustomLink} from "../../components/uiKit/links/CustomLink";


export function ForCustomers() {
    return (
        <div className={styles.cont}>
            <div className={stylesLight.title}>
                Заказчику
            </div>
            <div className={styles.border + " " + stylesLight.text}>
                Приветствуем вас на сайте, разработанном командой сотрудников, студентов и друзей Новосибирского
                государственного университета.
                Своей целью мы видим развитие проектной культуры в НГУ, Академгородке и за его пределами.
                В НГУ есть ряд активностей, в том числе реализация образовательной дисциплины «Основы проектной
                деятельности», в рамках которых студенты работают над различными проектами.
                Если у вас есть какая-нибудь проблема или потребность, при этом вы готовы рассказать о ней и
                предоставить нашим студентам возможность решить её, то мы приглашаем вас выступить заказчиком одного или
                нескольких проектов.
                Проекты, как правило, будут выполняться студентами 1 курса. Наши первокурсники полны идей и амбиций, но,
                к сожалению, ни сами студенты, ни преподаватели не могут гарантировать, что проект состоится и будет
                реализован успешно.
                Вбрасывание проектной идеи – рискованный шаг, который, однако, может принести бенефиты.
            </div>
            <div className={stylesLight.subtitle}>
                Как стать заказчиком?
            </div>
            <div className={stylesLight.text}>
                Для этого необходимо подать заявку, в которой:
                <li>описать проектную идею;</li>
                <li>сформулировать проблему или потребность, на решение/удовлетворение которой будет направлен проект;
                </li>
                <li>охарактеризовать контур употребления возможного проектного решения – ответить на вопрос «Кто, где и
                    как будет использовать результаты/наработки проекта?».
                </li>
                <br />
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                        <img src={icon} alt={'!'} style={{ flex: 1 }} />
                    </div>
                    <div className={styles.left}>
                        Ввиду того, что основная целевая аудитория сайта – студенты, мы обязаны предупредить заказчиков,
                        что не несем ответственности за реализацию студентами того или иного проекта, однако будем
                        стараться поддерживать и методически сопровождать движение проектных команд.
                    </div>
                </div>
            </div>
            <div className={styles.grey}>
                <div className={stylesLight.subtitle}>
                    К какой сфере может относиться проект?
                </div>
                <div className={stylesLight.text}>
                    Мы стараемся не вводить существенных ограничений. На данный момент рассматриваются проектные идеи по
                    следующим категориям:
                    Исследовательская деятельность; инженерно-технологическая деятельность; IT; образовательная
                    деятельность, социальная и творческая деятельность, популяризация науки, работа со школьниками;
                    спортивная деятельность; бизнес; инфраструктура; самоуправление; геймификация; экология.
                    Вы также можете предложить свою категорию, если это необходимо.
                </div>
            </div>
            <div className={stylesLight.subtitle}>
                Как поделиться своей проектной идеей?
            </div>
            <div className={stylesLight.text}>
                Пройти процедуру регистрации (нажать <CustomLink link={'/auth/login_type'}>«Войти»</CustomLink>) и
                заполнить проектную заявку в разделе «подать проект»
                вашего личного кабинета.
                Мы будем рады, если вы порекомендуете кого-то из своих коллег или сотрудников в качестве куратора или
                консультанта для группы студентов. Однако просим вас и куратора не давать студентам конкретных ТЗ. Для
                нас важно, чтобы участники команд сами искали свой путь: строили гипотезы, проводили эксперименты,
                ошибались или достигали запланированного, анализировали результаты, рефлексировали. Это опыт команды
                проекта и именно им нужно его прожить. Надеемся на ваше понимание.
                Будем признательны, если помимо текстовой заявки вы сможете поделиться с нами небольшим (на 1 – 5 минут)
                видеоматериалом, в котором вы опишите предлагаемую вами проблематику студенческого проекта.
            </div>
            <div className={styles.grey}>
                <div className={stylesLight.subtitle}>
                    Необходимо ли гарантировать выделение финансирования на реализацию проекта?
                </div>
                <div className={stylesLight.text}>
                    Проект – это всегда трата времени и других ресурсов, например, финансовых. Однако если проект,
                    заказчиком которого вы стали, требует ресурсов, которых у вас нет, то возможно команда
                    преподавателей и сами студенты смогут их найти. В общем, заказчиком в такой ситуации тоже можно
                    стать.
                    Если у вас есть серьезная задумка и даже свои ресурсы, которые вы готовы предоставить для ее
                    реализации, мы будем этому только рады.
                </div>
            </div>
            <div className={stylesLight.subtitle}>
                Каковы сроки реализации проекта?
            </div>
            <div className={stylesLight.text}>
                Сроки реализации проекта – это, как правило, 2 семестр учебного года студента, то есть февраль – май. С
                примерами проектных идей вы можете ознакомиться в разделе <CustomLink link={'/'}>«Проекты»</CustomLink>.
                Благодарим вас за инициативность и
                надеемся на плодотворное сотрудничество.
            </div>
        </div>
    )
}
