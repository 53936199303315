import React from 'react';
import styles from './TextBlock.module.css'
import stylesLight from "../../stylesLight.module.css"
import {ProjectKeyWordsListType} from "../../types/ProjectTypes";

type PropType = {
    title: string
    key_words: ProjectKeyWordsListType
}
export const KeyWordsBlock: React.FC<PropType> = ({title, key_words}) => {
    const words = key_words.map((i, index) => index === 0 ? i.name : i.name.toLowerCase())
    return (
            <div className={styles.block}>
                <div className={styles.block_title + " " + stylesLight.subtitle}>{title}</div>
                <div className={styles.block_description + " " + stylesLight.text}>{words.join(', ')}</div>
            </div>
    )
}
