import React from "react";

export function Group(){

    return <svg width="91" height="91" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity=".5" fill="#333">
            <path
                d="M45.46 61.17A18.66 18.66 0 0 0 64.1 42.53a18.66 18.66 0 0 0-18.64-18.64 18.66 18.66 0 0 0-18.64 18.64 18.66 18.66 0 0 0 18.64 18.64Z"/>
            <path
                d="m13.33 77.72 2.07 1.82a45.4 45.4 0 0 0 60.12 0l2.07-1.82-.07-.06a45.5 45.5 0 0 0 13.4-32.2A45.5 45.5 0 0 0 45.46 0 45.51 45.51 0 0 0 0 45.46c0 12.11 4.86 23.7 13.4 32.2l-.07.06ZM4.88 45.46A40.63 40.63 0 0 1 45.46 4.87a40.63 40.63 0 0 1 40.59 40.59c0 10.93-4.44 21.4-12.23 29.01a45.38 45.38 0 0 0-56.72 0A40.6 40.6 0 0 1 4.88 45.46Z"/>
        </g>
    </svg>


}
