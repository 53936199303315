import React, { useState } from "react";
import styles from './CabinetBar.module.css'
import { connect } from "react-redux";
import MainBar from "../../components/bars/MainBar";
import StudentBar from "../../components/bars/StudentBar"
import TeacherBar from "../../components/bars/TeacherBar"
// import TutorBar from "../../components/bars/TutorBar"
import CustomerBar from "../../components/bars/CustomerBar"
// import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { useHistory } from "react-router-dom";

function CabinetBarPre(props) {
    const history = useHistory()
    const statuses = props.states;
    const [putProject, setputProject] = useState(false)
    // const [Button, setButton] = useState(true)
    //console.log(statuses, 'CabinetBarPre')

    if (statuses.indexOf(2) > -1 && !putProject && !props.customer) {
        setputProject(true);
        // setButton(false)
    }
    return (
        <div className={styles.unionbar}>
            <div>
                <div className={styles.bar}>
                    <MainBar />
                </div>

                {!props.customer && !putProject &&
                    <button type="submit" className={styles.btn} style={{ marginRight: "1.875rem", alignSelf: "auto" }}
                        onClick={() => {
                            setputProject(true)
                            // setButton(false)
                            history.push('/cabinet/customer/info')
                        }}>
                        Стать заказчиком проекта
                    </button>}

                {(props.customer || putProject) && <h1 className={styles.head}>Заказчик</h1>}
                {(props.customer || putProject) && <div className={styles.bar}>
                    <CustomerBar />
                </div>}

                {statuses.includes(1) && <h1 className={styles.head}>Студент</h1>}
                {statuses.includes(1) && <div className={styles.bar}>
                    <StudentBar />
                </div>}
                {/*{statuses.includes(3) && <h1 className={styles.head}>Куратор</h1>}*/}
                {/*{statuses.includes(3) && <div className={styles.bar}>*/}
                {/*    <TutorBar/>*/}
                {/*</div>}*/}
                {statuses.includes(4) && <h1 className={styles.head}>Преподаватель</h1>}
                {statuses.includes(4) && <div className={styles.bar}>
                    <TeacherBar />
                </div>}
            </div>


        </div>
    )
    // } else {
    //     return (
    //         <div className={styles.unionbar}>
    //             <div className={styles.bar}>
    //                 <MainBar/>
    //             </div>
    //         </div>
    //     )
    // }
}

export const CabinetBar = connect((state) => ({
    states: state.user.user?.statuses ?? [],
    customer: state.user.user?.customer
}))(CabinetBarPre)
