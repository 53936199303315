import ReactModal from "react-modal";
import React, {useState} from "react";
import {ImagePicker} from "../inputs/imagePicker";
import styles from "./Modals.module.css"
import {uploadFile} from "redux/api/UserApi";
import ExitCross from "icons/ExitCross.svg";


export function ImageModal(props) {

    const [isLoading, setIsLoading] = useState(false);
    const ImageUploader = uploadFile(props.onSave, (event) => console.log(event), true)
    return <ReactModal isOpen={props.isOpen}
                       className={styles.container}
                       onRequestClose={() => props.onClose()}
                       ariaHideApp={false}
                       style={{overlay: {zIndex: 1000}}}>
        <div className={styles.content}>
            <div onClick={() => props.onClose()} className={styles.exitCross}>
                <img src={ExitCross} alt="Закрыть"/>
            </div>
            <div className={styles.contentBlock}>
                <div style={{
                    maxWidth: "93%",
                    alignSelf: "center",
                }}
                     onClick={(e) => {
                         if (isLoading) {
                             e.preventDefault();
                         }

                     }}>
                    <ImagePicker onSave={(d) => {
                        setIsLoading(true);
                        ImageUploader(d).then(() => setIsLoading(false))
                            .then(() => props.onClose()).catch((e) => console.error(e));
                    }}/>
                </div>
            </div>
        </div>


    </ReactModal>
}

