import React from 'react';
import styles from './TagBlock.module.css'
import {Tag} from './Tag'
import {ProjectTagsListType} from 'types/ProjectTypes'

type PropsType = {
    tags?: ProjectTagsListType
}

export default function TagBlock(props: PropsType) {
    const {tags = []} = props;
    return <div className={styles.tag_block}>
        {tags.map(i => <Tag key={i.id ? i.id : i.label} text={i.name ? i.name : i.value}/>)}
    </div>
}

