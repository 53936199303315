import React from 'react';
import ReactDOM from 'react-dom';
// import "@fontsource/fira-sans-condensed";
// import 'semantic-ui-css/semantic.min.css'
import './index.css';
import App from './App';
import {store} from './redux/store'
import {Provider} from 'react-redux';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
// import {MetaTags} from "./features/utils";
import "./features/stringPrototype"
import reportWebVitals from "./reportWebVitals";

if (document.location.host === 'projects.mmf.nsu.ru') {
    Sentry.init({
        dsn: "https://473d2481c1494d26b6ac5c07b4d9c8ff@o1023955.ingest.sentry.io/5990098",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App/>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
);

reportWebVitals();

