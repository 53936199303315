import React from "react";
import defaultStyles from "../defaultPage.module.css"
import stylesLight from "../../../../stylesLight.module.css";
import style from "./SubmitProject.module.css";
import {MyProjectsList} from "../../../../components/lists/MyProjectsList";


export function MyProjectsCustomer() {
    return (<div className={style.itemsContainer}>
        <div className={defaultStyles.title + " " + stylesLight.subtitle} style={{margin: 0}}>
            Мои проекты
        </div>
        <div className={defaultStyles.description + " " + stylesLight.text}>
            В этом разделе вы можете ознакомиться со всеми проектами, которые вы подали, а также узнать их статус.
        </div>
        <div className={defaultStyles.itemsContainer}>
            <MyProjectsList role={1} error="Увы, но вы еще не подали ни один проект."/>
        </div>
    </div>)
}
