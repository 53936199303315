import React from "react";
import defaultStyles from '../defaultPage.module.css'
import {useHistory} from "react-router-dom";
import {CustomerInformationForm} from "../../../../components/forms/Customer/CustomerInfoForm";
import {useDispatch} from "react-redux";
import {getMeThunk} from "../../../../redux/reducers/userReducer";
import {api_connector} from "../../../../redux/api/UserApi";
import {toast} from "react-toastify";

export function CustomerInfo(_) {
    const dispatch = useDispatch();
    const history = useHistory()
    return (
        <div className={defaultStyles.itemsContainer}>
            <CustomerInformationForm onSubmit={(v) => {
                // send data .then
                console.log(v)
                if ((v?.jobs ?? []).length > 0)
                    v?.jobs.filter(j => j.isNew).map(j => delete j['id'])
                api_connector.updMe({
                    customer: {
                        ...v,
                        contactWayForStudent: (v?.contactWayForStudentValue ?? undefined) && v.contactWayForStudent,
                        jobs: v?.jobs ?? []
                    }
                }).then((r) => r.data).then(() => dispatch(getMeThunk())).then(history.push('/cabinet/customer/submit_project')).then(() => toast.info("Вы успешно заполнили анкету заказчика! Вы можете приступить к подаче проекта сейчас или сделать это позже через вкладку «Подать проект» в личном кабинете заказчика."))
                ///cabinet/customer/submit_project
                console.log("CustomerInformationForm ", v)
            }}/>
        </div>
    )
}
