import styles from "./TagsBlock.module.css";
import React, {useEffect, useState} from "react";
import {api_connector} from "redux/api/UserApi";
import {TagItem} from "./TagItem";
import {ProjectTagsListType} from "types/ProjectTypes";

type PropsType = {
    selectedTags: Array<number>
    setSelectedTags: (tags: Array<number>) => void
}


export function TagsBlock(props: PropsType): JSX.Element {
    const [tags, setTags] = useState<ProjectTagsListType>([])
    const {selectedTags, setSelectedTags} = props

    useEffect(() => {
        api_connector.getTags().then(d => setTags(d)).catch((e) => console.error(e))
    }, [])

    return <div className={styles.tagList}>
        {
            tags && tags.map((tag) => <TagItem key={tag.id} tag={tag} onClick={() => {
            if (selectedTags.filter(t => t === tag.id).length > 0) {
                setSelectedTags(selectedTags.filter(t => t !== tag.id))
            } else {
                setSelectedTags([...selectedTags, tag.id])
            }
        }}/>)
        }
    </div>
}
