import { FC, useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import { api_connector } from "redux/api/UserApi";

import { Loader } from "../../../components/uiKit/Loader";

interface ChildrenProps {
    data: ProjectHistoryItem[];
    projectId: number;
}

interface Props {
    projectId: number;
    children: (props: ChildrenProps) => JSX.Element;
}
export const HistoryContainer: FC<Props> = ({ projectId, children }) => {
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState<ProjectHistoryItem[]>([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        api_connector
            .getHistory(projectId)
            .then(setHistory)
            .catch((e) => {
                console.error(e);
                setError(true);
            })
            .finally(() => setLoading(false));

    }, [projectId]);

    if (loading) {
        return <Loader />
    }

    if (error || !history) {
        return <Redirect to="/" />
    }

    return children({ data: history, projectId })
};
