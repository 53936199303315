import React from "react";

export function PersonalInfo() {
    return (<svg width="20" height="20" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.81.6C6.12.22 5.34 0 4.54 0h-.82v1.64h.82A2.91 2.91 0 0 1 6 7.05a2.92 2.92 0 0 1-4.36-2.51c0-.5.14-1.01.4-1.45l.4-.7-1.41-.83-.41.71A4.54 4.54 0 1 0 6.8.61ZM6.81 11.53c-.69-.4-1.47-.61-2.27-.61h-.82v1.64h.82A2.91 2.91 0 0 1 6 17.97a2.92 2.92 0 0 1-4.36-2.51c0-.51.14-1.01.4-1.45l.4-.71-1.41-.82-.41.7a4.56 4.56 0 0 0 1.66 6.21 4.55 4.55 0 0 0 4.54-7.86ZM13.19 8.47a4.55 4.55 0 0 0 6.2-6.2l-.4-.7-1.42.81.4.71a2.91 2.91 0 0 1-1.06 3.97c-.88.5-2.02.5-2.9 0a2.92 2.92 0 0 1-1.46-2.52 2.91 2.91 0 0 1 2.9-2.9h.83V0h-.82a4.55 4.55 0 0 0-2.27 8.47ZM18.98 12.48l-1.41.82.4.7a2.91 2.91 0 0 1-1.06 3.97 2.92 2.92 0 0 1-4.36-2.51 2.91 2.91 0 0 1 2.91-2.9h.82v-1.64h-.82a4.55 4.55 0 1 0 3.93 2.27l-.4-.7Z"
                fill="#fff"/>
        </svg>
    )
}
