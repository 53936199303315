export const required = (value: any) => value ? undefined : 'Обязательное поле'

export const requiredArray = (value: Array<any>) => value && Array.isArray(value) && value.length !== 0 ? undefined : 'Обязательное поле'

export const email = (value: string) =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
                'Неверный email адресс' : undefined

export const maxLength = (max: number) => (value: string) =>
        value && value.length > max ? `Должно быть небольше ${max} символов` : undefined

export const maxLength15 = maxLength(15);

const minValue = (min: number) => (value: string) =>
        value && value.length < min ? `Должно быть длиннее ${min} символов` : undefined

export const minLength8 = minValue(8);


