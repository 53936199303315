import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useHistory} from "react-router-dom";
import styles from './DropdownMenu.module.css'
import DownArrow from "../../icons/down-arrow.svg";

const ItemCSS = {
    fontFamily: "Fira Sans Condensed, sans-serif",
    fontSize: "1.2rem",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "130%",
    color: 'var(--input-text-color)',
}
export default function DropdownMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [title, setTitle] = useState('Меню');
    const open = Boolean(anchorEl);
    const history = useHistory()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    function handleClose(name) {
        setTitle(name)
        setAnchorEl(null);
    }

    return (
        <div>
            <div className={styles.button}>
                <Button
                    id="demo-positioned-button"
                    aria-controls="demo-positioned-menu"
                    aria-haspopup="true"
                    color="success"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        fontFamily: "Fira Sans Condensed, sans-serif",
                        fontSize: "1.2rem",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "130%",
                        color: 'var(--input-text-color)',
                        textTransform: "initial",
                    }}
                >
                    {title}&nbsp;&nbsp;
                    <img className={styles.arrow} src={DownArrow} alt={'arrow'}/>
                </Button>
            </div>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose('Меню')}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                <MenuItem onClick={() => {
                    handleClose('О нас')
                    history.push('/about')
                }} sx={ItemCSS}>О нас</MenuItem>

                <MenuItem onClick={() => {
                    handleClose('Проекты')
                    history.push('/')
                }} sx={ItemCSS}>Проекты</MenuItem>

                <MenuItem onClick={() => {
                    handleClose('Бездна')
                    history.push('/bezdna')
                }} sx={ItemCSS}>Бездна</MenuItem>

                <MenuItem onClick={() => {
                    handleClose('Архив')
                    history.push('/archive')
                }} sx={ItemCSS}>Архив</MenuItem>

                <MenuItem onClick={() => {
                    handleClose('Справочная информация')
                    history.push('/reference_information')
                }} sx={ItemCSS}>Справочная информация</MenuItem>

                <MenuItem onClick={() => {
                    handleClose('Заказчику')
                    history.push('/for_customers')
                }} sx={ItemCSS}>Заказчику</MenuItem>

            </Menu>
        </div>
    );
}
