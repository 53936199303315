import React, {useEffect, useState} from 'react';
import styles from '../Project/Project.module.css'
import {api_connector} from "../../redux/api/UserApi";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {Loader} from "../../components/uiKit/Loader";
import {EditProjectForm} from "./EditProjectForm";
import {toast} from "react-toastify";

export default function StudentProjectEdit() {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [project, setProject] = useState(true)
    // const projectId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    const {projectId} = useParams()

    useEffect(() => {
        setIsLoading(true);
        api_connector.getProject(projectId).then(d => {
            if (d) {
                d.tags = d.tags.map((item) => (
                    {
                        value: item.id, label: item.name
                    })
                )
                d.files = d.files.map((item) => (
                    {
                        ...item,
                        id: item.id,
                        fileName: item.name,
                        originalName: item.name,
                        type: item.is_link ? '1' : '0',
                    })
                )
                d.tutor = d.members.filter((t) => t.status === 2).map(t => ({
                    value: t.id,
                    label: t.last_name + " " + t.first_name + " " + t.middle_name,
                    ...t
                }))
                d.students = d.members.filter((t) => t.status === 3).map(t => ({
                    value: t.id,
                    label: t.last_name + " " + t.first_name + " " + t.middle_name,
                    ...t
                }))
                d.customer = d.members.filter((t) => t.status === 1).map(t => t)
                setProject(d);
                setIsLoading(false);
            }
        }).catch((e) => {
            setHasError(true);
            setIsLoading(false);
            console.error(e)
        })
    }, [projectId])

    if (isLoading) {
        return (<Loader/>)
    }
    if (hasError) {
        return (
            <Redirect to="/"/>
        )
    }

    async function submitProject(data) {
        let tags = [];
        const newTags = data.tags?.filter((t) => t?.__isNew__).map((t) => ({name: t.label})) ?? []
        if (newTags.length) {
            const createdTagsId = await api_connector.createTags(newTags)
            if (createdTagsId) {
                tags = createdTagsId.map(t => t.id)
            }
        }
        const existsTags = data?.tags?.filter((t) => !t?.__isNew__).map((t) => t.value)

        let keyWords = [];
        const newKeyWords = data.key_words?.filter((t) => t?.__isNew__).map((t) => ({name: t.name})) ?? []
        if (newKeyWords.length) {
            const createdWordsId = await api_connector.createKeyWords(newKeyWords)
            if (createdWordsId) {
                keyWords = createdWordsId.map(t => t.id)
            }
        }
        const existsKeyWords = data?.key_words?.filter((t) => !t?.__isNew__).map((t) => t.id)
        const tutor = data?.tutor?.filter((t) => !t?.__isNew__).map((t) => ({
            id: t?.value || t.id,
            status: 2,
            description: ''
        }))
        const students = data?.students?.filter((t) => !t?.__isNew__).map((t) => ({
            id: t?.value || t.id,
            status: 3,
            description: ''
        }))
        const customer = data?.customer?.map((t) => ({
            id: t?.value || t.id,
            status: 1,
            description: ''
        }))
        const dataToBack = {
            ...data,
            tags: [...tags,
                ...(existsTags && existsTags.length > 0 ? (existsTags?.filter((t) => t) ?? []) : [])
            ],
            members_to_update: [...customer, ...tutor, ...students],
            // tutors: (data.tutors &¡& data?.tutors.length > 0) ? unique(data?.tutors?.map((t) => t.tutors?.value) ?? []).filter((t) => t) ?? [] : [],

            key_words: [...keyWords,
                ...(existsKeyWords && existsKeyWords.length > 0 ? (existsKeyWords?.filter((t) => t) ?? []) : [])
            ],
            files: (data.files && data.files.length > 0) ? data.files?.map(f => ({
                name: f.fileName,
                link: f.link,
                is_link: f.type === '1'
            })) ?? [] : []
        }
        console.log(dataToBack)
        api_connector.editProject(project.id, dataToBack).then((r) => r.data).then(() => {
        }).then(() => {
            if (dataToBack.status !== 6) {
                history.push(`/project/${project.id}`)
            } else {
                history.push(`/`)
            }
        }).then(() => toast.info('Данные успешно изменены.'));
    }

    return (
        <div className={styles.page}>
            <EditProjectForm data={project} onSubmit={(v) => submitProject(v)}/>
        </div>
    )

}
