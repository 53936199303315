import {v4 as getUUID} from "uuid";
import PlusImage from "../../icons/PlusItem.svg";
import styles from "./input.module.css";
import React from "react";
import {JobInput} from "./JobInput";
import {Person} from "../../screens/Project/Person";
import {connect} from "react-redux";
import {getDescription} from "../../features/utils";
import {WrappedFieldArrayProps} from "redux-form";
import {AppState} from "../../redux/store";
import {CustomerJobType, UserType} from "types/UserTypes";

type FormCustomerJobType = {
    isNew?: boolean
} & CustomerJobType

type PropsType = WrappedFieldArrayProps<FormCustomerJobType> & MapStateToPropsType & {
    placeholderForAddBtn: string
}

const MultiJob: React.FC<PropsType> = (
        {
            fields,
            placeholderForAddBtn,
            meta: {error, submitFailed},
            user
        }) => {

    const isShow = fields.length > 0 && getDescription(fields) !== "";
    const marginTop = (isShow && "0") || "1rem";

    return <div>
        {fields.map((member, index) =>
                <JobInput key={fields.get(index).id} member={member}
                          onRemove={() => fields.remove(index)}/>
        )}

        {isShow && <div style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
        }}>
            <div style={{marginBottom: "1rem"}}> Предпросмотр:</div>

            {user && <Person fullName={user.full_name} role={'Заказчик'}
                             description={getDescription(fields)} image={user.image}/>}
        </div>}

        <div onClick={() => fields.push(
                {
                    id: getUUID(),
                    isNew: true
                }
        )} style={{
            display: "flex",
            marginTop: marginTop
        }}>
            <img src={PlusImage} alt={placeholderForAddBtn}/>
            <div className={styles.MultiInputSimple_placeholder_add}>
                {placeholderForAddBtn}
            </div>
        </div>
        {submitFailed && error && <span>{error}</span>}
    </div>
}

type MapStateToPropsType = {
    user?: UserType
}

const MapStateToProps = (state: AppState): MapStateToPropsType => ({
    user: state.user?.user
})

export default connect(MapStateToProps)(MultiJob)
