import React from "react";
import defaultStyles from '../defaultPage.module.css'
import {StudentInfoForm} from "../../../../components/forms/Student/StudentInfoForm";

export function StudentInfo() {
    return (
        <div className={defaultStyles.itemsContainer}>
            <StudentInfoForm onSubmit={() => {
                // send data .then
                // v?.jobs.filter(j => j.isNew).map(j => delete j['id'])
                // api_connector.updMe({
                //     customer: {
                //         ...v,
                //         contactWayForStudent: (v?.contactWayForStudentValue ?? undefined) && v.contactWayForStudent,
                //         jobs: v?.jobs

                // }).then((r) => r.data).then(() => dispatch(getMeThunk())).then(history.push('/cabinet/customer/submit_project'))
                ///cabinet/customer/submit_project
                // console.log("StudentInformationForm ", v)
            }}/>
        </div>
    )
}
