import React from 'react';
import styles from './TextBlock.module.css'
import stylesLight from "../../stylesLight.module.css"
import {ProjectRequirementsListType, ProjectTasksListType} from "../../types/ProjectTypes";


function ul(array: ProjectTasksListType | ProjectRequirementsListType) {
    return (
            <div className={stylesLight.text}>
                <ul>
                    {array.map(i => (
                            <li key={i.id}>{i.name}</li>
                    ))}
                </ul>
            </div>
    )
}

function ol(array: ProjectTasksListType | ProjectRequirementsListType) {

    array = array.map(i => (
            {
                ...i,
                name: `${i.name.capitalize()};`
            }
    ))


    const last_string = array[array.length - 1].name
    array[array.length - 1].name = `${last_string.substring(0, last_string.length - 1)}.`
    return (
            <div className={stylesLight.text}>
                <ol>
                    {array.map(i => (
                            <li key={i.id}>{i.name}</li>
                    ))}
                </ol>
            </div>
    )
}

type PropsType = {
    title: string
    array: ProjectTasksListType | ProjectRequirementsListType
    type: boolean
}

export const CustomList: React.FC<PropsType> = ({title, array, type}) => {
    if (!array.length)
        return <> </>
    return (
            <div className={styles.block}>
                <div className={styles.block_title + " " + stylesLight.subtitle}>{title}</div>
                {type && ul(array)}
                {!type && ol(array)}
            </div>
    )
}
