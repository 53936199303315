import React from 'react';
import styles from './Person.module.css'
import {PersonIcon} from "icons/PersonIcon";
import stylesLight from "stylesLight.module.css"


type PropsType = {
    fullName: string,
    role: string,
    description: string,
    image: string
}

export const Person: React.FC<PropsType> = (
        {
            fullName,
            role,
            description,
            image
        }) =>
        <div className={styles.person}>
            <div className={styles.person_title}>
                <div>
                    {image && <img src={image} alt={fullName} className={styles.image}/>}
                    {!image && <div className={styles.image}>
                        {!image && <PersonIcon/>}
                    </div>}
                </div>
                <div>
                    <div className={stylesLight.doit + " " + styles.person_name}>{fullName}</div>
                    <div className={stylesLight.tiny}>{role}</div>
                </div>
            </div>
            <div className={styles.person_text + " " + stylesLight.small}>{description}</div>
        </div>
