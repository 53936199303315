import React, {useEffect, useState} from 'react';
import styles from '../Project/Project.module.css'
import editstyles from './EditProject.module.css'
import {StatusBar} from '../../components/bars/StatusBar'
import {TeamBlock} from '../Project/TeamBlock'
import {Button} from "../../components/uiKit/btns/Button";
import {Field, FieldArray, reduxForm} from "redux-form";
import {MultiInputFile} from "../../components/inputs/MultiInputFile";
import {InputTextArea} from "../../components/inputs/inputTextArea";
import {MultiInputSimple} from "../../components/inputs/MultiInputSimple";
import {SimpleInput} from "../../components/inputs";
import {MultiSelector} from "../../components/inputs/inputMultiSelector";
import {api_connector} from "../../redux/api/UserApi";
import stylesLight from "../../stylesLight.module.css";
import {connect} from "react-redux";
import {minLength8, required, requiredArray} from "../../components/forms/validators";
import TagBlock from "../Project/TagBlock";
import {Description} from "../Project/Description";
import {TextBlock} from "../Project/TextBlock";
import {CustomList} from "../Project/CustomList";
import {KeyWordsBlock} from "../Project/KeyWordsBlock";
import {Materials} from "../Project/Materials";
import {SelectorInput} from "../../components/inputs/inputSelector";
import {Redirect} from "react-router-dom";
import {Loader} from "../../components/uiKit/Loader";

const editFields = {
    name: false,
    tags: false,
    description: false,
    problem_need: false,
    goal: false,
    tasks: false,
    use_contour: false,
    requirements: false,
    key_words: false,
    files: false,
    team: false
}

const statusFileds = [
    {
        label: "Модерация",
        value: 1,
        selectedBackgroundColor: "#C2A9FF",
    },
    {
        label: "Бездна",
        value: 2,
        selectedBackgroundColor: "#FFBB00",
    },
    {
        label: "Открыт",
        value: 4,
        selectedBackgroundColor: "var(--main-green-color)",
    },
    {
        label: "Закрыт",
        value: 5,
        selectedBackgroundColor: "#EA3E23",
    },
    {
        label: "Удален",
        value: 6,
        selectedBackgroundColor: "#000000",
    },

];

function StudentProjectPre(props) {
    const [tags, setTags] = useState([]);
    const [isLoading1, setIsLoading1] = useState(true)
    const [isLoading2, setIsLoading2] = useState(true)

    const user = props.user
    let edit = false;
    if (props.data.is_member || user?.statuses.includes(5) || props.data.has_my_students || props.data.is_customer) {
        edit = true;
    }
    useEffect(
        () => {
            setIsLoading1(true)
            api_connector.getTags()
                .catch(() => {
                }).then((d) => d.map(i => ({
                value: i.id,
                label: i.name
            }))).then(setTags).then(() => setIsLoading1(false));
        }
        , [])

    const {data, initialize} = props;
    useEffect(() => {
        setIsLoading2(true);
        if (user?.statuses.includes(5)) {                // поля, которые может редактировать модератор
            ['name', 'tags', 'description', 'goal', 'tasks', 'problem_need', 'use_contour', 'key_words', 'requirements', 'files', 'team'].map((item) => (
                editFields[item] = true
            ))
        } else if (data.has_my_students) {        // поля, которые может редактировать преподаватель
            ['name', 'tags', 'description', 'goal', 'tasks', 'problem_need', 'use_contour', 'key_words', 'requirements', 'files', 'team'].map((item) => (
                editFields[item] = true
            ))
        } else if (data.is_customer) {            // поля, которые может редактировать заказчик
            ['description', 'problem_need', 'use_contour', 'requirements', 'files'].map((item) => (
                editFields[item] = true
            ))
        } else {                                        // поля, которые может редактировать студент
            ['goal', 'tasks', 'key_words', 'key_words', 'files'].map((item) => (
                editFields[item] = true
            ))
        }
        initialize(data)
        setIsLoading2(false);
    }, [initialize, data, user?.statuses])

    if (isLoading1 || isLoading2) {
        return (<Loader/>)
    }
    if (!edit) {
        return <Redirect to={`/project/${props.data.id}`}/>;
    } else {
        return (
            <form onSubmit={props.handleSubmit}>
                <div className={styles.page}>
                    {editFields.name && <div className={editstyles.title_bar}>
                        <div className={editstyles.block}>
                            <Field label="Добавить текст" type="text" component={SimpleInput}
                                   name={'name'} projName={true} validate={[required]}/>
                        </div>
                        <div className={editstyles.selector}>
                            <Field name="status" type="text" component={SelectorInput}
                                   options={statusFileds} isStatusEdit={true}/>
                        </div>
                    </div>}
                    {!editFields.name && <div className={styles.title_bar}>
                        <h1 className={styles.title + " " + stylesLight.title}>{props.data.name}</h1>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <StatusBar status={props.data.status} status_name={props.data.status_name}/>
                        </div>
                    </div>
                    }

                    <div className={styles.info}>
                        <div className={styles.textinfo}>


                            {editFields.tags && <div className={editstyles.block}>
                                <div>
                                    <div className={editstyles.block_title + " " + stylesLight.subtitle}
                                         style={{marginBottom: "1rem"}}>Тэги проекта:
                                    </div>
                                    <Field component={MultiSelector} options={tags} name={'tags'}
                                           label='Выберите подходящие сферы деятельности' stylecomp={true}
                                           show_on_touch={props.hasErrors} validate={[requiredArray]}/>
                                </div>
                            </div>}
                            {!editFields.tags && <TagBlock elements={props.data.tags}/>}


                            {editFields.description && <div className={editstyles.block}>
                                <div className={editstyles.block_title + " " + stylesLight.subtitle}>Описание:</div>
                                <Field type="text" component={InputTextArea} validate={[required, minLength8]}
                                       name={'description'}/>
                            </div>}
                            {!editFields.description && props.data.description &&
                            <Description description={props.data.description}/>}


                            {editFields.problem_need && <div className={editstyles.block}>
                                <div
                                    className={editstyles.block_title + " " + stylesLight.subtitle}>Проблема/потребность:
                                </div>
                                <Field label="Добавить текст" type="text" validate={[required, minLength8]}
                                       component={InputTextArea} name={'problem_need'}/>
                            </div>}
                            {!editFields.problem_need && props.data.problem_need &&
                            <TextBlock title="Проблема/потребность:"
                                       description={props.data.problem_need}/>}

                            {editFields.goal && <div className={editstyles.block}>
                                <div className={editstyles.block_title + " " + stylesLight.subtitle}>Цель:</div>
                                <Field label="Добавить текст" type="text"
                                       component={InputTextArea} name={'goal'}/>
                            </div>}
                            {!editFields.goal && props.data.goal &&
                            <TextBlock title="Цель:" isEdit={false} description={props.data.goal}/>}


                            {editFields.tasks && <div className={editstyles.block}>
                                <div className={editstyles.block_title + " " + stylesLight.subtitle}>Задачи:</div>
                                <FieldArray name="tasks" component={MultiInputSimple} name_suffix={"name"}
                                            label='Проанализировать текущую ситуацию на рынке'
                                            placeholderForAddBtn={'Добавить задачу'}/>
                            </div>}
                            {!editFields.tasks && props.data.tasks.length !== 0 &&
                            <CustomList title="Задачи:" isEdit={false} type={false}
                                        array={props.data.tasks}/>}

                            {editFields.use_contour && <div className={editstyles.block}>
                                <div className={editstyles.block_title + " " + stylesLight.subtitle}>Контур
                                    употребления:
                                </div>
                                <Field label="Добавить текст" type="text" validate={[required, minLength8]}
                                       component={InputTextArea} name={'use_contour'}/>
                            </div>}
                            {!editFields.use_contour && props.data.use_contour &&
                            <TextBlock title="Контур употребления:"
                                       description={props.data.use_contour}/>}

                            {editFields.requirements && <div className={editstyles.block}>
                                <div className={editstyles.block_title + " " + stylesLight.subtitle}>Требования к
                                    участникам:
                                </div>
                                <FieldArray name="requirements" component={MultiInputSimple} name_suffix={"name"}
                                            label='Например, необходим опыт работы с Python'
                                            placeholderForAddBtn={'Добавить требование к участникам'}/>
                            </div>}
                            {!editFields.requirements && props.data.requirements.length !== 0 &&
                            <CustomList title="Требования к участникам:" isEdit={false} type={false}
                                        array={props.data.requirements}/>}

                            {editFields.key_words && <div className={editstyles.block}>
                                <div className={editstyles.block_title + " " + stylesLight.subtitle}>Ключевые
                                    слова/использованные технологии:
                                </div>
                                <FieldArray name="key_words" component={MultiInputSimple} name_suffix={"name"}
                                            label='Например, глубокое обучение'
                                            placeholderForAddBtn={'Добавить ключевое слово/технологию'}/>
                            </div>}
                            {!editFields.key_words && props.data.key_words.length !== 0 &&
                            <KeyWordsBlock title="Ключевые слова/использованные технологии:" isEdit={false}
                                           key_words={props.data.key_words}/>}

                            {editFields.files && <div className={editstyles.block}>
                                <div className={editstyles.block_title + " " + stylesLight.subtitle}>Материалы проекта
                                </div>
                                <FieldArray name="files" component={MultiInputFile} name_suffix={"files"}
                                            label='Добавление файлов и ссылок'
                                            placeholderForAddBtn={'Добавить требование к участникам'}/>
                            </div>}
                            {!editFields.files && props.data.files.length !== 0 &&
                            <Materials files={props.data.files}/>}
                        </div>
                        <div className={styles.right_column}>
                            {!editFields.team &&
                            <TeamBlock team={props.data.members} max_members={props.data.max_members}
                                       count_members={props.data.count_members}/>}
                            {editFields.team && <div className={stylesLight.doit}>
                                <TeamBlock
                                    team={props.data.members.length > 1 ? [props.data.members[0]] : props.data.members}
                                    max_members={props.data.max_members}
                                    count_members={props.data.count_members}/>
                                <h3 className={editstyles.role_name}>Кураторы:</h3>
                                <FieldArray name="tutor" component={MultiInputSimple}
                                            name_suffix={undefined}
                                            isTutor={true}
                                            label='Начните вводить фамилию куратора'
                                            placeholderForAddBtn={'Добавить куратора проекта'}
                                            internal_type={true}/>
                                <h3 className={editstyles.role_name}>Участники:</h3>
                                <FieldArray name="students" component={MultiInputSimple}
                                            name_suffix={undefined}
                                            label='Начните вводить фамилию участника'
                                            placeholderForAddBtn={'Добавить участника проекта'}
                                            internal_type={true}/>
                            </div>}
                        </div>
                    </div>
                    <div style={{marginBottom: "5rem"}}/>
                    <Button type="submit" className={styles.save_button}>Сохранить изменения</Button>
                </div>
            </form>
        )

    }
}


const form = reduxForm({form: 'EditProjectForm'})(StudentProjectPre)
export const EditProjectForm = connect(state => ({
    user: state.user?.user,
}))(form)
