import React from 'react';
import styles from './Materials.module.css'
import FileIcon from "icons/FileIcon.svg";
import LinkItemProject from "icons/LinkItemProject.svg";
import stylesLight from "stylesLight.module.css"
import {ProjectFilesListType} from "types/ProjectTypes";

type PropsType = {
    files: ProjectFilesListType
}
export const Materials: React.FC<PropsType> = ({files}) => {
    return (
            <div className={styles.block}>
                <div className={styles.title + " " + stylesLight.title}>Материалы проекта</div>
                <div className={styles.files + " " + stylesLight.text}>
                    <div className={styles.container}>
                        {files.map(i => (
                                <a href={i.link} className={styles.a_style}>
                                    <div className={styles.file_comp}>
                                        {i.is_link &&
                                        <img src={LinkItemProject} alt={`Перейти по ссылке`}
                                             className={styles.LinkImg}/>}
                                        {!i.is_link &&
                                        <img src={FileIcon} alt={`Перейти по ссылке`} className={styles.LinkImg}/>}
                                        <div className={styles.link_name}>{i.name}</div>
                                    </div>
                                </a>
                        ))}
                    </div>
                </div>
            </div>
    )
}
