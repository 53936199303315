import React from "react";
import defaultStyles from "../defaultPage.module.css"
import {MyProjectsList} from "../../../../components/lists/MyProjectsList";

export function MyProjectsStudent() {
    return (<div>
        <div className={defaultStyles.title} style={{margin: 0}}>
            Мои проекты
        </div>
        <div className={defaultStyles.description}>
            В этом разделе вы можете ознакомиться с проектами, в которых вы участвуете.
        </div>
        <div className={defaultStyles.itemsContainer}>
            <MyProjectsList role={3} error="Увы, но вы еще не участвуете ни в одном проекте."/>
        </div>
    </div>)
}
