import React from 'react';
import styles from './TeamBlock.module.css'
import {Person} from "./Person";
import stylesLight from "../../stylesLight.module.css"
import {ProjectMemberType} from "../../types/ProjectTypes";

type PropsType = {
    team: Array<ProjectMemberType>
    count_members: number
    max_members: number
}

export const TeamBlock: React.FC<PropsType> = (
        {
            count_members,
            team,
            max_members
        }) => {
    return (
            <div className={styles.team}>
                <div className={styles.title_stat}>
                    <h3 className={styles.team_title + " " + stylesLight.subtitle}>Команда проекта</h3>
                    {max_members &&
                    <div className={styles.closed + " " + stylesLight.subtitle}>{count_members}/{max_members}</div>}
                </div>
                <div className={styles.person_list + " " + stylesLight.text}>
                    {team.map(i => (
                            <Person key={i.id} fullName={i.first_name + ' ' + i.middle_name + ' ' + i.last_name}
                                    role={i.status_name}
                                    description={i.description} image={i.image}/>
                    ))}
                </div>
            </div>
    )
}
