import React from "react";
import {SimpleInput} from "./inputSimple";
import {Field} from "redux-form";
import styles from './input.module.css'
import deleteIcon from "../../icons/DeleteIcon.svg";

export const JobInput = ({member, onRemove}) => {
    return (
        <div className={styles.jobContainer}>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between"
            }}>
                <div className={styles.subTitle}>
                    Информация о месте работы
                </div>
                <div style={{
                    marginTop: "1.3rem",
                    marginLeft: "1rem",
                    maxWidth: "35px",
                    display: "flex",
                    alignItems: "center",
                }} onClick={onRemove}>
                    <img src={deleteIcon} alt={`Удалить`}
                    />
                </div>
            </div>

            <Field type="text" component={SimpleInput} name={`${member}.organization`} label='Организация'/>
            <Field type="text" component={SimpleInput} name={`${member}.organization_url`}
                   label='Ссылка на организацию'/>
            <Field type="text" component={SimpleInput} name={`${member}.division`} label='Подразделение'/>
            <Field type="text" component={SimpleInput} name={`${member}.division_url`} label='Ссылка на подразделение'/>
            <Field type="text" component={SimpleInput} name={`${member}.position`} label='Должность'/>

        </div>

    )
}
