import { format, parseJSON } from "date-fns";
import { FC, useMemo } from "react";
import { toast } from "react-toastify";

import { StatusBar } from "../../components/bars/StatusBar";
import { Button } from "../../components/uiKit/btns/Button";
import EditIcon from "../../icons/editing.png";
import { api_connector } from "../../redux/api/UserApi";
import { UserStateType } from "../../redux/reducers/userReducer";
import stylesLight from "../../stylesLight.module.css";
import { ProjectType } from "../../types/ProjectTypes";
import { UserStatusesListType } from "../../types/UserTypes";
import { CustomList } from "./CustomList";
import { Description } from "./Description";
import { History } from "./History";
import { KeyWordsBlock } from "./KeyWordsBlock";
import { Materials } from "./Materials";
import styles from "./Project.module.css";
import TagBlock from "./TagBlock";
import { TeamBlock } from "./TeamBlock";
import { TextBlock } from "./TextBlock";

type Props = {
    showActions?: boolean;
    user: UserStateType;
    statuses?: UserStatusesListType;
    project: ProjectType;
    refetch?: () => void;
    historyDate?: string;
};

const ableToEdit = (project: ProjectType, statuses: UserStatusesListType = [], showActions = true) =>
    showActions && (project.is_member || statuses?.includes(5) || project.has_my_students || project.is_customer);

const formatHistoryDate = (date: string) => `(${format(parseJSON(date), "dd.MM.yyyy, HH:mm")})`;

export const StudentProjectView: FC<Props> = ({
    project,
    user,
    statuses = [],
    refetch = () => undefined,
    showActions = true,
    historyDate,
}) => {
    let belong,
        logged_student,
        bad_statuses,
        opened = false;

    if (user?.isLoggedIn) {
        if (project.is_member) {
            belong = true;
        }
        logged_student = true;
    }

    if (project.status === 5 || project.status === 6) {
        bad_statuses = true;
    }

    if (project.is_recruiting) {
        opened = true;
    }

    const edit = ableToEdit(project, statuses, showActions);
    const showHistory = useMemo(
        () => showActions && (statuses.includes(3) || statuses.includes(4) || statuses.includes(5)),
        [showActions, statuses]
    );

    return (
        <>
            <div className={styles.page}>
                <div className={styles.title_bar}>
                    <h1 className={styles.title + " " + stylesLight.title}>
                        {project.name} {historyDate ? formatHistoryDate(historyDate) : null}
                    </h1>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {showHistory && <History projectId={project.id} />}
                        <div className={styles.edit_icon_block}>
                            {edit && (
                                <a key={"edit"} href={`/project/${project.id}/edit`}>
                                    <img src={EditIcon} className={styles.edit_icon} alt={"EditIcon"} />
                                </a>
                            )}
                        </div>
                        <StatusBar status={project.status} status_name={project.status_name} />
                    </div>
                </div>
                <TagBlock tags={project.tags} />
                <div className={styles.info}>
                    <div className={styles.textinfo + " " + stylesLight.subtitle}>
                        {project.description && <Description description={project.description} />}
                        {project.problem_need && (
                            <TextBlock title="Проблема/потребность:" description={project.problem_need} />
                        )}
                        {project.goal && <TextBlock title="Цель:" description={project.goal} />}

                        {project.tasks && project.tasks.length > 0 && (
                            <CustomList title="Задачи:" type={false} array={project.tasks} />
                        )}

                        {project.use_contour && (
                            <TextBlock title="Контур употребления:" description={project.use_contour} />
                        )}
                        {project.requirements && project.requirements.length > 0 && (
                            <CustomList title="Требования к участникам:" type={false} array={project.requirements} />
                        )}
                        {project.key_words && project.key_words.length > 0 && (
                            <KeyWordsBlock
                                title="Ключевые слова/использованные технологии:"
                                key_words={project.key_words}
                            />
                        )}
                        {project.comment && (
                            <TextBlock title="Комментарий от заказчика:" description={project.comment} />
                        )}
                        {project.files && project.files.length > 0 && <Materials files={project.files} />}
                    </div>
                    <div className={styles.right_column}>
                        {project.max_members && (
                            <TeamBlock
                                team={project.members}
                                count_members={project.count_members}
                                max_members={project.max_members}
                            />
                        )}

                        {showActions && opened && logged_student && belong && !bad_statuses && statuses?.includes(1) && (
                            <Button
                                className={styles.leave_button + " " + stylesLight.doit}
                                onClick={() => {
                                    api_connector
                                        .loseProject(project.id)
                                        .then(refetch)
                                        .catch(() => {
                                            toast.error("Ошибка. Не удалось покинуть проект");
                                        });
                                }}
                            >
                                Покинуть проект
                            </Button>
                        )}
                        {showActions && opened && logged_student && !belong && !bad_statuses && statuses?.includes(1) && (
                            <Button
                                className={styles.leave_button + " " + stylesLight.doit}
                                style={{ backgroundColor: "#c6eef5", color: "black" }}
                                onClick={() => {
                                    api_connector
                                        .beMember(project.id)
                                        .then(refetch)
                                        .catch(() => {
                                            toast.error("Ошибка. Не удалось вступить в проект");
                                        });
                                }}
                            >
                                Cтать участником проекта
                            </Button>
                        )}

                        {!opened && (
                            <div
                                className={stylesLight.text}
                                style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    display: "block",
                                    position: "relative",
                                }}
                            >
                                Набор закрыт
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ marginBottom: "5rem" }} />
                {/*<button className={styles.save_button}>Сохранить изменения</button>*/}
            </div>
        </>
    );
};
