import React, {useRef, useState} from "react";
import styles from './Collapse.module.css'
import OpenArrow from "icons/OpenArrow";

type PropsType = {
    title: string | JSX.Element
}
export const Collapse: React.FC<PropsType> =
        ({
             title,
             children
         }) => {
            const [open, setOpen] = useState("");
            const [height, setHeight] = useState("0");
            const parentRef = useRef<HTMLDivElement>(null);

            function toggle() {
                setOpen(open === "" ? styles.active : "");
                setHeight((open === styles.active || parentRef.current == null)
                        ? "0" : `calc(${parentRef.current.scrollHeight}px )`);
            }

            return <div style={{width: "100%"}} onClick={toggle}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        {title}
                    </div>
                    {/*<img src={arrow} className={styles.arrow + " " + open} alt={'arrow'}/>*/}
                    <OpenArrow className={styles.arrow + " " + open}/>
                </div>
                <div className={styles.item}
                     style={{height: height}} ref={parentRef}>
                    {children}
                </div>
            </div>
        }
