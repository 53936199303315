import React from "react";

export function Info() {

    return <svg width="7" height="20" viewBox="0 0 7 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.3178 4.03929C6.77269 3.58025 7 3.02294 7 2.36934C7 1.71684 6.77269 1.15972 6.3178 0.696481C5.86304 0.233089 5.31671 0 4.6763 0C4.03844 0 3.48689 0.233089 3.02944 0.696481C2.57467 1.15972 2.3447 1.71684 2.3447 2.36934C2.3447 3.02294 2.57467 3.58025 3.02944 4.03929C3.48689 4.49828 4.03844 4.72861 4.6763 4.72861C5.31671 4.72861 5.86304 4.49828 6.3178 4.03929ZM5.26962 19.3874C6.0904 18.9708 6.52437 18.1878 6.09824 17.8297C5.85254 17.6236 5.53096 17.9647 5.2958 17.9647C4.79134 17.9647 4.43574 17.8751 4.2319 17.6947C4.02542 17.5128 3.92609 17.1758 3.92609 16.6784C3.92609 16.4795 3.95484 16.1882 4.01758 15.8029C4.0803 15.4165 4.1535 15.0709 4.2319 14.7697L5.2069 11.0232C5.29837 10.6792 5.36639 10.3011 5.40293 9.89043C5.4369 9.47534 5.45529 9.18823 5.45529 9.02615C5.45529 8.23742 5.19906 7.59504 4.68932 7.10038C4.1822 6.60737 3.45553 6.35846 2.51452 6.35846C1.99176 6.35846 1.43502 6.44233 0.852133 6.66119C-0.290173 7.09603 -0.0365867 8.1764 0.216907 8.1764C0.731827 8.1764 1.07688 8.27144 1.25988 8.46058C1.44286 8.6482 1.53692 8.98218 1.53692 9.464C1.53692 9.72833 1.50297 10.0255 1.44543 10.3466C1.38528 10.6679 1.31483 11.0104 1.22596 11.3699L0.2457 15.1306C0.162097 15.5257 0.0992366 15.8796 0.0574699 16.1936C0.0182933 16.5078 0 16.8149 0 17.1162C0 17.8881 0.261334 18.5246 0.786707 19.0277C1.31222 19.5324 2.11993 20 3.06612 20C3.68039 20 4.55863 19.7514 5.26962 19.3874Z"
            fill="white"/>
    </svg>
}
