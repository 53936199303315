import { useCallback, useEffect, useState } from "react";
import { api_connector } from "redux/api/UserApi";
import { ProjectType } from "types/ProjectTypes";

export const useProject = (projectId: number | string) => {
    const [project, setProject] = useState<Nullable<ProjectType>>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const fetch = useCallback(() => {
        setLoading(true);

        api_connector
            .getProject(projectId)
            .then(setProject)
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [setLoading, setProject, setError, projectId]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return { loading, project, error, refetch: fetch } as const;
};
