import React, {useState} from "react";
import defaultStyles from "../defaultPage.module.css";
import {DivisionByStudent} from "./DivisionByStudent/DivisionByStudent";
import {DivisionByProject} from "./DivisionByProject/DivisionByProject";
import {Button} from "components/uiKit/btns/Button";

const pages = [
    {
        title: "Разделение по студентам",
        page: <DivisionByStudent/>
    },
    {
        title: "Разделение по проектам",
        page: <DivisionByProject/>
    }
]

export function TeacherCabinet() {
    const [openedPage, setOpenedPage] = useState(0)
    return (
        <div>
            <div className={defaultStyles.title} style={{margin: 0}}>
                Мои группы
            </div>
            <p>На этой странице Вы сможете отслеживать деятельность студентов</p>
            <div className={defaultStyles.block} style={{flexWrap: "nowrap"}}>
                {pages.map((el, index) =>
                    <Button key={index}
                            className={defaultStyles.rectangle + " " +
                            (openedPage === index ? defaultStyles.rectangle_clicked : "")}

                            onClick={() => setOpenedPage(index)}
                    >{el.title}</Button>
                )}
            </div>
            {pages[openedPage].page}
        </div>
    )
}
