import React from "react";
import styles from "screens/Auth/Register/Register.module.css";
import {Link} from "react-router-dom";
import {Button} from "components/uiKit/btns/Button";

export function LoginType() {
    return (
        <div className={styles.containerAuth}>
            <h1 className={styles.title}> Вход </h1>
            <div className={styles.formBlock}>
                <a href={'/api/v1/auth/oidc/authenticate/'}>
                    <Button className={styles.btn}>
                        Войти с g.nsu.ru
                    </Button>
                </a>
                <div className={styles.links}>
                    <Link to='/auth/login'>У меня нет g.nsu.ru</Link>
                </div>
            </div>
        </div>
    )
}
