import React, {useState} from "react";
import ReactModal from "react-modal";
import ProgressBar from "@ramonak/react-progress-bar";
import styles from "./Modals.module.css";
import inputStyles from "../inputs/input.module.css";
import stylesLight from "../../stylesLight.module.css";


import ExitCross from "icons/ExitCross.svg"

import {uploadFile} from "redux/api/UserApi";

export function FileUploadModal(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(undefined);
    const [fileTitle, setFileTitle] = useState("");
    // const [isDisabled, setIsDisabled] = useState(false);

    const [loadPercent, setLoadPercent] = useState(0);
    const FileUploader = uploadFile((u) => props.onSave({
        type: '0',
        link: u,
        fileName: fileTitle,
        originalName: file.name
    }), (event) => {
        setLoadPercent(Math.round((event.loaded * 100) / event.total));
    });

    if (file === undefined && props.lastFile !== undefined) {
        setFile(props.lastFile);
        // setIsDisabled(true);
    }

    return <ReactModal isOpen={props.isOpen}
                       className={styles.container}
                       onRequestClose={() => props.onClose()}
                       ariaHideApp={false}
                       style={{overlay: {zIndex: 1000}}}>
        <div className={styles.content}>
            <div onClick={() => props.onClose()} className={styles.exitCross}>
                <img src={ExitCross} alt="Закрыть"/>
            </div>
            <div className={styles.contentBlock}>
                <div className={styles.title + " " + stylesLight.subtitle}>
                    Загрузка материалов проекта:
                </div>

                <div className={styles.subTitle + " " + stylesLight.doit}>
                    Выберете файл и введите его название, которое будет отображаться на сайте.
                </div>

                {/*<FileUploadForm onSubmit={(d) => console.log(d)}/>*/}
                {/*{fileTitle}*/}
                {/*<Field type="text" component={SimpleInput} name='tmp' label='Введите название файла'*/}
                {/*       validate={[required]} onChange={(e) => setFileTitle(e.target.value)}/>*/}
                {/*<Field type="file" component={SimpleInput} name='file' label='Название файла' validate={[required]}*/}
                {/*       defaultValue={file} onChange={(e) => setFile(e.target.files[0])} disabled={isDisabled}/>*/}
                {/*<Field type="file" component={SimpleInput} name='email' label='Email'/>*/}
                <input type="text" value={fileTitle} onChange={(e) => setFileTitle(e.target.value)}
                       className={inputStyles.simple + " " + stylesLight.doit} placeholder={"Введите название файла"}/>

                {props.lastFile && <input type="text" value={props.lastFile.name} disabled/>}
                {!props.lastFile && (<div>
                        <input id="file-input" type="file" onChange={(e) => setFile(e.target.files[0])}
                               style={{display: "none"}}/>
                        <div className={inputStyles.simple + " " + styles.fileInput + " " + stylesLight.doit}>
                            <label htmlFor="file-input" style={{display: "flex", alignItems: "center", flex: 1}}>
                                <span>{file?.name || "Выберите файл"}</span>
                            </label>
                            <img className={styles.fileImg} src="https://bmm.mca.nsu.ru/icons/screpka.svg" alt=""/>
                        </div>
                    </div>

                )}
                <div style={{marginTop: "2rem"}}>{isLoading &&
                <ProgressBar completed={loadPercent} style={{marginTop: "1rem", marginBottom: "2rem"}}/>}</div>
                <button type="button" className={styles.btn} style={{marginTop: "2rem", marginBottom: "2rem"}}
                        disabled={!fileTitle || !file} onClick={() => {
                    if (!fileTitle || !file) {
                        return;
                    }
                    setIsLoading(true);
                    FileUploader(file).then(() => setIsLoading(false)).then(() => props.onClose()).then(() => {
                        setFile(undefined);
                        setFileTitle("")
                    });
                }}>
                    Сохранить
                </button>

            </div>


        </div>


    </ReactModal>
}

/* onClick={(f) => {
setIsLoading(true)
FileUploader(fileUrl).then(() => setIsLoading(false))
// .then(() => props.onClose())
.catch((e) => console.error(e));
}}*/
