import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
    return <svg className={props.className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m75.457 24.543c-0.78125-0.78125-2.0469-0.78125-2.8281 0l-22.629 22.629-22.629-22.629c-0.78125-0.78125-2.0469-0.78125-2.8281 0s-0.78125 2.0469 0 2.8281l22.629 22.629-22.629 22.629c-0.78125 0.78125-0.78125 2.0469 0 2.8281s2.0469 0.78125 2.8281 0l22.629-22.629 22.629 22.629c0.78125 0.78125 2.0469 0.78125 2.8281 0s0.78125-2.0469 0-2.8281l-22.629-22.629 22.629-22.629c0.78125-0.78125 0.78125-2.0469 0-2.8281z"/>
    </svg>

}
