import React from "react";
import styles from "./input.module.css"
import CreatableSelect from 'react-select/creatable';
import stylesLight from "../../stylesLight.module.css";
import {StylesConfig} from "react-select/dist/declarations/src/styles";
import {OptionType} from "types/ReactSelectTypes";
import {WrappedFieldProps} from "redux-form";

type IsMulti = true;


type PropsType = WrappedFieldProps & {
    options: Array<OptionType>
    label: string
}


export function MultiSelector(props: PropsType) {
    const {options, label, input, meta: {touched, error}} = props;
    const customStyles: StylesConfig<OptionType, IsMulti> = {
        option: (provided, state) => ({
            ...provided,
            // borderBottom: '1px dotted pink',
            color: state.isSelected ? 'white' : 'black',
            // padding: 20,
        }),
        control: (provided, state) => {
            return ({
                ...provided,
                width: "100%",
                minHeight: "3.5rem",
                // border: "none",
                background: "var(--input-bg-color)",
                borderRadius: "0.9375rem",
                // textIndent: "1.25rem",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "1.066rem",
                lineHeight: "130%",
                color: "var(--input-text-color)",
                padding: "0 0",

                boxShadow: "none",

                border: ((state.menuIsOpen || state.isFocused) && !(touched && error)) ? "1px solid var(--input-hover-color)" : "none",
                ...(state.menuIsOpen ? {
                    borderBottomRightRadius: "0",
                    borderBottomLeftRadius: "0",
                } : {}),
                ...((touched && error) ? {
                    border: "1px solid red",
                    // borderBottom: "0"
                } : {}),

                "&:hover": !(touched && error) ? 'border:  && "1px solid var(--input-hover-color)"' : '',
                "&:focus": !(touched && error) ? 'border:  && "1px solid var(--input-hover-color)"' : '',

                // "&:focus": {
                //     border: !(touched && error) && "1px solid var(--input-hover-color)"
                // },
            });
        },
        singleValue: (provided) => {
            // const opacity = state.isDisabled ? 0.5 : 1;
            // const transition = 'opacity 300ms';
            return {...provided};
        },
        input: (provided) => ({
            ...provided,
            display: "flex",
            marginLeft: "0.6rem",

        }),
        placeholder: (provided) => ({
            ...provided,
            marginLeft: "0.6rem",
        }),
        container: (provided) => ({
            ...provided,
            // borderRadius: "0.9375rem"
        }),
        menu: (provided) => ({
            ...provided,
            boxShadow: "none",
            background: "var(--input-bg-color)",
            marginTop: "-15px",
            borderColor: "var(--input-hover-color)",
            borderRadius: "0.9375rem",
            borderRight: "1px solid var(--input-hover-color)",
            borderLeft: "1px solid var(--input-hover-color)",
            borderBottom: "1px solid var(--input-hover-color)",
            borderTopRightRadius: "0",
            borderTopLeftRadius: "0",
            ...((touched && error) ? {
                border: "1px solid red",
                borderTop: "0"
            } : {})
        })
    }
    return (
            <div className={styles.multiSelector + " " + stylesLight.doit}>
                <CreatableSelect

                        onChange={(d, _) => {
                            input.onBlur(input.value);
                            input.onChange(d);
                        }}
                        onBlur={() => {
                            input.onBlur(input.value);
                        }}
                        styles={customStyles}
                        isMulti
                        options={options}
                        placeholder={label}
                        // className={styles.multiSelector}
                        // classNamePrefix={styles.multiSelector}
                        // menuIsOpen

                        value={input.value}
                        // menuIsOpen={true}
                        // initialSelectedIndex={0}
                        // selectedBackgroundColor={defaultColor}
                        // backgroundColor={bgColor}
                        // fontColor={fontColor}
                        // disabled={disabled}
                />
                {touched && ((error && <span className={styles.errorMessage}>{Array.isArray(error) ? error.map(s =>
                        <React.Fragment>{s}<br/></React.Fragment>) : error}</span>))}
            </div>
    )


}
