import React from "react";
import styles from "./DivisionByProject.module.css";
import {Collapse} from "components/uiKit/collapse/Collapse";
import {ButtonLink} from "components/uiKit/btns/ButtonLink";
import {EllipseCollapseItem} from "components/uiKit/collapse/EllipseCollapseItem";
import {ProjectTypeInDivision} from "./DivisionByProject";

type PropsType = {
    project: ProjectTypeInDivision
}

export const ProjectsInfo: React.FC<PropsType> = ({project}) => {
    return (<EllipseCollapseItem>
        <Collapse title={project.name}>
            <ul>
                {project.users.map(u => {
                    return <li key={u.id}>
                        {u.full_name}, {u.group.name}
                    </li>
                })}</ul>
            <ButtonLink className={styles.projectBtn} to={`/project/${project.id}`}> Перейти к проекту </ButtonLink>
        </Collapse>
    </EllipseCollapseItem>)
}
