import React, {useEffect, useState} from "react";
import styles from "./input.module.css"
import Select from 'react-select';
import {api_connector} from "../../redux/api/UserApi";
import stylesLight from '../../stylesLight.module.css'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? 'var(--main-green-color)' : 'white',
    }),
    control: (provided) => ({
        ...provided,
        // fontFamily: "Fira Sans Condensed",
        width: "100%",
        minHeight: "3.5rem",
        border: "none",
        background: "#33333311",
        borderRadius: "0.9375rem",
        // fontStyle: "normal",
        // fontWeight: "normal",
        // fontSize: "1.0625rem",
        // lineHeight: "150%",
        color: "var(--input-text-color)",
        padding: "0 0",
    }),
    singleValue: (provided) => {
        // const opacity = state.isDisabled ? 0.5 : 1;
        // const transition = 'opacity 300ms';

        return {...provided};
    },
    input: (provided) => ({
        ...provided,
        display: "flex",
        marginLeft: "0.6rem",

    } + " " + stylesLight.text),
    placeholder: (provided) => ({
        ...provided,
        marginLeft: "0.6rem",
    })

}

export function MultiSelectorForTeachers({label, input, disabled = false}) {
    const [teachers, setTeachers] = useState([])
    useEffect(() => {
        api_connector.searchTeachers().then((r) => r.data).then((d) => d.map(i => ({
            value: i.id, label: i.full_name
        }))).then((d) => setTeachers(d)).catch(() => setTeachers([]));
    }, [])
    return (
        <div className={styles.multiSelector}>
            <Select
                onChange={(d, _) => {
                    input.onBlur();
                    input.onChange(d);
                }}
                styles={customStyles}
                options={teachers}
                cacheOptions
                placeholder={label}
                value={input.value}
                isDisabled={disabled}
            />
        </div>
    )


}
