import React, {useEffect, useState} from "react";
import stylesLight from "../../../stylesLight.module.css"
import styles from "./SubmitProjectForm.module.css"
import {SimpleInput} from "../../inputs";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {InputTextArea} from "../../inputs/inputTextArea";
import {MultiSelector} from "../../inputs/inputMultiSelector";
import {api_connector} from "../../../redux/api/UserApi";
import {minLength8, required, requiredArray} from "../validators";
import {Button} from "../../uiKit/btns/Button";
import {OptionType} from "types/ReactSelectTypes";

export type SubmitProjectFirstFieldsType = {
    name?: string,
    description?: string,
    problem_need?: string,
    tags?: Array<OptionType>,
    use_contour?: string,
}

export type OwnProps = {
    hasErrors?: any
}


function SubmitProjectFirstPreRedux(props: InjectedFormProps<SubmitProjectFirstFieldsType, OwnProps> & OwnProps) {

    const [tags, setTags] = useState<Array<OptionType>>([]);
    useEffect(() => {
                api_connector.getTags().then((d) => d.map(i => ({
                    id: i.id,
                    value: i.id,
                    label: i.name
                }))).then(d => setTags(d)).catch((e) => console.error(e));
            }
            , [])
    return (
            <form onSubmit={props.handleSubmit} className={styles.formContainer}>
                <div className={styles.title + " " + stylesLight.subtitle}>
                    О проекте
                </div>
                <div className={styles.itemsContainer}>
                    <div className={styles.subTitle + " " + stylesLight.doit}>
                        Рабочее название проекта:
                    </div>
                    <div>
                        <Field type="text" component={SimpleInput} name='name' label='Рабочее название проекта'
                               stylecomp={true} validate={[required]} show_on_touch={props.hasErrors}/>
                    </div>
                    <div className={styles.subTitle + " " + stylesLight.doit}>
                        Краткое описание проекта:
                    </div>
                    <div>
                        <Field type="textarea" component={InputTextArea} name='description'
                               label='Краткое описание проекта'
                               stylecomp={true} validate={[required, minLength8]} show_on_touch={props.hasErrors}/>
                    </div>
                    <div className={styles.subTitle + " " + stylesLight.doit}>
                        Проблема/потребность, на решение/удовлетворение которой будет направлен проект:
                    </div>
                    <div>
                        <Field type="textarea" component={InputTextArea} name='problem_need'
                               label='Проблема/потребность'
                               stylecomp={true} validate={[required, minLength8]} show_on_touch={props.hasErrors}/>
                    </div>

                    <div className={styles.subTitle + " " + stylesLight.doit}>
                        Сферы деятельности, к которой можно отнести проблему/потребность (можно выбрать несколько):
                    </div>


                    <div className={styles.field}>
                        <Field component={MultiSelector} options={tags} name='tags'
                               label='Выберите подходящие сферы деятельности' stylecomp={true}
                               validate={[requiredArray]} show_on_touch={props.hasErrors}/>
                    </div>
                    <div className={styles.subTitle + " " + stylesLight.doit}>
                        Контур употребления: кто, где и как будет использовать результаты/наработки проекта?
                    </div>
                    <div>
                        <Field type="textarea" component={InputTextArea} name='use_contour' label='Контур употребления'
                               stylecomp={true} validate={[required, minLength8]} show_on_touch={props.hasErrors}/>
                    </div>

                </div>

                <Button type="submit" className={styles.btn} onClick={(_) => _}>
                    <div className={stylesLight.button_text}> Далее</div>
                </Button>
            </form>
    )
}

export const SubmitProjectFirstForm = reduxForm<SubmitProjectFirstFieldsType, OwnProps>({
    form: 'customer-submit-project',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(SubmitProjectFirstPreRedux)
