import { format, parseISO } from "date-fns";
import { FC } from "react";
import { Link } from "react-router-dom";

import css from "./styles.module.css";
import { formatChangeReason } from "./utils";

const formatDate = (date: string) => format(parseISO(date), "dd.MM.yyyy, HH:mm");

interface Props extends ProjectHistoryItem {
    projectId: number;
}
export const ListItem: FC<Props> = ({ history_date, history_id, history_change_reason = "", projectId }) => {
    return (
        <Link to={`/project/${projectId}/history?date=${history_date}`}>
            <div className={css.listItemWrapper}>
                <div className={css.listItem}>
                    <div className={css.listItemHeading}>
                        <b>#{history_id}</b> {formatDate(history_date)}
                    </div>
                    <div className={css.changelog}>
                        {(history_change_reason || "").split(";").map((reason) => (
                            <p key={reason}>{formatChangeReason(reason)}</p>
                        ))}
                    </div>
                </div>
            </div>
        </Link>
    );
};
