import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { Loader } from "../../components/uiKit/Loader";
import { AppState } from "../../redux/store";
import { useProjectByDate } from "./hooks";
import { StudentProjectView } from "./StudentProjectView";

type ParamsType = {
    projectId: string;
};

const selector = (state: AppState) => ({
    user: state.user,
    statuses: state.user.user?.statuses || [],
});

const StudentProjectHistory: FC = () => {
    const history = useHistory();
    const search = new URLSearchParams(history.location.search);
    const date = search.get("date") || new Date().toJSON();

    const { projectId } = useParams<ParamsType>();
    const { user, statuses } = useSelector(selector);
    const { loading, error, project } = useProjectByDate(projectId, date);

    if (loading) {
        return <Loader />;
    }

    if (error || !project) {
        return <Redirect to="/" />;
    }

    return (
        <StudentProjectView user={user} statuses={statuses} project={project} showActions={false} historyDate={date} />
    );
};

export default StudentProjectHistory;
