import React from "react";
import styles from "screens/Auth/Register/Register.module.css";
import {login} from "redux/reducers/userReducer";
import {useDispatch} from "react-redux";
import {LoginForm, LoginFormType} from "components/forms/Login";
import {api_connector} from "redux/api/UserApi";
import {SubmissionError} from "redux-form";


export function Login() {
    const dispatch = useDispatch();
    return (
        <div className={styles.containerAuth}>
            <h1 className={styles.title}> Авторизация </h1>
            <div className={styles.formBlock}>
                <LoginForm onSubmit={(val: LoginFormType) => {
                    return api_connector.login(val).then(() => dispatch(login())).catch((e) => {
                        if (e.response.status === 401) {
                            e.response.data._error = e.response.data.detail;
                            throw new SubmissionError(e.response.data)
                        }
                    })
                }}/>
            </div>
        </div>
    )
}
