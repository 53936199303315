import React from "react";

export function CustomLink({link, children, noBlank = false}) {
    return (
        <a href={link} rel="noopener noreferrer nofollow" target={!noBlank ? "_blank" : ""} style={{
            textDecoration: "none",
            color: "var(--input-text-color)",
            borderBottom: "2px solid var(--main-green-color)"
        }}>{children}</a>
    )
}

