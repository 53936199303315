import {useDispatch} from "react-redux";
import styles from './HeaderMenu.module.css'
import React from "react";
import {Dropdown} from 'semantic-ui-react'
import {useHistory} from "react-router-dom";
import {logoutUserThunk} from "../../redux/reducers/userReducer";
import Avatar from "@mui/material/Avatar";
import {UserType} from "../../types/UserTypes";
import {Loader} from "../uiKit/Loader";

function getColor(): string {

    const colors = ['#5BC8AC', '#F18D9E', '#9E9DFF', '#FFCCAC', '#B2DBD5', '#FFCCAC', '#2B616D', '#DE8BD9', '#C6EEF5'];
    const index = Math.floor(Math.random() * colors.length);
    return colors[index]
}

type PropsType = {
    user?: UserType
}

export function HeaderMenu(props: PropsType) {
    const dispatch = useDispatch();
    const history = useHistory()

    if (props.user)
        return (
                <div className={styles.menu}>
                    {/*<div className={styles.notifications}>*/}
                    {/*    <div className={styles.bell}>*/}
                    {/*        <HeaderBell/>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                    <div className={styles.headerPerson}>
                        <div className={styles.img}>
                            <Avatar className={styles.letter} style={{borderRadius: "0.625rem"}}
                                    sx={{bgcolor: getColor()}}
                                    alt={props.user?.last_name + " " + props.user?.first_name}
                                    src={props.user.image}>
                                {props.user && (props.user?.first_name[0] + props.user?.last_name[0])}
                            </Avatar>
                        </div>

                        <Dropdown simple item
                                  text={props.user?.last_name + " " + props.user?.first_name[0] + ". " + (props.user.middle_name ? (props.user.middle_name[0] + '.') : "")}
                                  className={styles.dropdown}
                                  style={{marginLeft: 0}}
                        >
                            <Dropdown.Menu style={{transform: "translateX(-20%)"}}>
                                <Dropdown.Item onClick={() => history.push('/cabinet')}>Личный кабинет</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/cabinet/customer/info')}>Подать
                                    проект</Dropdown.Item>
                                <Dropdown.Item onClick={() => dispatch(logoutUserThunk())}>Выйти</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
        )
    return <Loader/>
}
