import {BoxLoading as LoaderItem} from "react-loading-typescript";
import React from "react";


export const Loader = () => <LoaderItem color={"var(--main-green-color)"}
                                        size={'default'}
                                        speed={0.5}
                                        style={{
                                            margin: "auto",
                                            position: "absolute",
                                            inset: "0px"
                                        }}/>
