import React, {useEffect} from 'react';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Header} from "./components/header";
import {Footer} from "./components/footer/Footer";
import {Projects} from "./screens/Projects";
import StudentProject from "./screens/Project/StudentProject";
import StudentProjectHistory from "./screens/Project/StudentProjectHistory";
import {Register} from "./screens/Auth/Register";
import {Cabinet} from "./screens/Cabinet";
import {connect, useDispatch} from "react-redux";
import {AuthRoute} from "./features/AuthRoute";
import {Login} from "./screens/Auth/Login/Login";
import {RefInfo} from "./screens/HeaderInfo/RefInfo";
import Bezdna from "./screens/HeaderInfo/Bezdna";
import Archive from "./screens/HeaderInfo/Archive";
import {LoginType} from "./screens/Auth/Login/LoginType";
import {getMeThunk} from "./redux/reducers/userReducer";
import "./App.css"
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ForCustomers} from "./screens/HeaderInfo/ForCustomers";
import {About} from "./screens/HeaderInfo/About";
import {Loader} from "./components/uiKit/Loader";
import Linkify from "react-linkify";
import StudentProjectEdit from "./screens/EditProject/EditProject";
import {AppState} from "./redux/store";

type MapStateToProps = {
    isLoading: boolean
    isLoggedIn: boolean
}

function App(props: MapStateToProps) {
    const dispatch = useDispatch();
    const isLoading = props.isLoading
    useEffect(() => {
        dispatch(getMeThunk())
    }, [dispatch, props.isLoggedIn])
    useEffect(() => {
        if (document.location.host !== 'projects.mmf.nsu.ru') {
            toast.error(<Linkify>
                Это тестовая версия сайта. Перейдите, пожалуйста, на более новую версию https://projects.mmf.nsu.ru/
            </Linkify>, {autoClose: false})
        }
    }, [])
    if (isLoading) {
        return (
                <Loader/>
        )
    }
    return (
            <div style={{display: "flex", flexDirection: "column"}} id={'outer-container'}>
                <Router>
                    <Header/>
                    <div className="content" id={'page-wrap'}>
                        <Switch>
                            {/*<Route path='/cabinet' component={Cabinet}/>*/}

                            <AuthRoute exact path='/auth/register' type="guest" component={Register}
                                       redirectTo={'/auth/login_type'}/>
                            <AuthRoute exact path='/auth/login' type="guest" component={Login} redirectTo={'/cabinet'}/>
                            <AuthRoute exact path='/auth/login_type' type="guest" component={LoginType}
                                       redirectTo={'/cabinet'}/>
                            <AuthRoute path='/cabinet' type="private" component={Cabinet}/>

                            <Route exact path='/' component={Projects}/>
                            <Route exact path='/project/:projectId' component={StudentProject}/>
                            <Route exact path='/project/:projectId/history' component={StudentProjectHistory}/>
                            <Route exact path='/project/:projectId/edit' component={StudentProjectEdit}/>
                            <Route exact path='/reference_information' component={RefInfo}/>
                            <Route exact path='/bezdna' component={Bezdna}/>
                            <Route exact path='/archive' component={Archive}/>
                            <Route exact path='/for_customers' component={ForCustomers}/>
                            <Route exact path='/about' component={About}/>
                            {/*<Route exact path='/image' component={ImageForm}/>*/}


                        </Switch>
                        <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover/>
                    </div>
                    <Footer/>
                </Router>
            </div>
    );
}

export default connect((s: AppState): MapStateToProps => ({
    isLoading: s.user.isLoading && s.user.isFirst,
    isLoggedIn: s.user.isLoggedIn
}))(App);
