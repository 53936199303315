import {Field, WrappedFieldArrayProps} from "redux-form";
import React from "react";
import {v4 as getUUID} from 'uuid';
import {SimpleInput} from "./inputSimple";
import {MultiSelectorForTutors} from "./inputMultiSelectorForTutors";
import PlusImage from "icons/PlusItem.svg"
import DeleteIcon from "icons/DeleteIcon.svg"
import styles from "./input.module.css"
import {OptionType} from "../../types/ReactSelectTypes";

export type OwnPropsType = {
    name_suffix: string
    internal_type?: boolean
    isTutor: boolean
    label: string
    placeholderForAddBtn: string
}


export type ArrayComponentProps = WrappedFieldArrayProps<OptionType>

export const MultiInputSimple: React.FC<ArrayComponentProps & OwnPropsType> = ({
                                                                                   fields,
                                                                                   label,
                                                                                   name_suffix,
                                                                                   internal_type = false,
                                                                                   placeholderForAddBtn,
                                                                                   isTutor = false,
                                                                                   meta: {error, submitFailed}
                                                                               }) => {
    return <div>
        {fields.map((member, index) => {
            const {id} = fields.get(index);
            return (<div key={id} style={{display: "flex"}}>
                <div style={internal_type ? {flex: 1, marginTop: "1.4rem"} : {flex: 1}}>
                    <Field type="text" component={internal_type ? MultiSelectorForTutors : SimpleInput}
                           name={name_suffix ? `${member}.${name_suffix}` : member} label={label} isTutor={isTutor}/>
                </div>
                <div style={{
                    marginTop: "1.3rem",
                    marginLeft: "1rem",
                    display: "flex",
                    alignItems: "center",
                }} onClick={() => fields.remove(index)}>
                    <img src={DeleteIcon} key={index} alt={`Удалить ${placeholderForAddBtn} ${index}`}/>
                </div>
            </div>);
        })}
        <div onClick={() => fields.push({id: getUUID(), __isNew__: true})}
             style={{display: "flex", marginTop: "1.26rem",}}>
            <img src={PlusImage} alt={placeholderForAddBtn}/>
            <div className={styles.MultiInputSimple_placeholder_add}>
                {placeholderForAddBtn}
            </div>

        </div>
        {submitFailed && error && <span>{error}</span>}
    </div>

}
