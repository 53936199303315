import React from "react";
import styles from "./input.module.css"
import AsyncSelect from 'react-select/async';
import {api_connector} from "../../redux/api/UserApi";
import {StylesConfig} from "react-select/dist/declarations/src/styles";
import {OptionType} from "../../types/ReactSelectTypes";
import {WrappedFieldProps} from "redux-form";

const customStyles: StylesConfig<OptionType, true> = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? 'var(--main-green-color)' : 'white',
    }),
    control: (provided) => ({
        ...provided,
        fontFamily: "Fira Sans Condensed",
        width: "100%",
        minHeight: "3.5rem",
        border: "none",
        background: "#33333311",
        borderRadius: "0.9375rem",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.0625rem",
        lineHeight: "150%",
        color: "var(--input-text-color)",
        padding: "0 0",
    }),
    singleValue: (provided) => {
        return {...provided};
    },
    input: (provided) => ({
        ...provided,
        display: "flex",
        marginLeft: "0.6rem",

    }),
    placeholder: (provided) => ({
        ...provided,
        marginLeft: "0.6rem",
    })

}

const loadOptions_tutors = (inputValue: string, callback: any) => {
    if (inputValue.length >= 3) {
        api_connector.searchUser(inputValue, 3).then((r) => r.data).then((d) => d.map(i => ({
            value: i.id, label: i.full_name,
        }))).then((d) => callback(d)).catch(() => callback([]));
    }
};

const loadOptions_students = (inputValue: string, callback: any) => {
    if (inputValue.length >= 3) {
        api_connector.searchUser(inputValue, 1).then((r) => r.data).then((d) => d.map(i => ({
            value: i.id, label: i.full_name,
        }))).then((d) => callback(d)).catch(() => callback([]));
    }
};

type PropsType = WrappedFieldProps & {
    options: Array<OptionType>
    label: string
    isTutor: boolean
}

export function MultiSelectorForTutors(props: PropsType) {
    const {options, label, input, isTutor} = props
    console.log(isTutor)
    return (
            <div className={styles.multiSelector}>
                <AsyncSelect
                        onChange={(d, _) => {
                            input.onBlur(input.value);
                            input.onChange(d);
                        }}
                        styles={customStyles}
                        options={options}
                        cacheOptions
                        placeholder={label}
                        isSearchable
                        loadOptions={isTutor ? loadOptions_tutors : loadOptions_students}
                        value={input.value?.value ? input.value : undefined}
                        // initialSelectedIndex={0}
                        // selectedBackgroundColor={defaultColor}
                        // backgroundColor={bgColor}
                        // fontColor={fontColor}
                        // disabled={disabled}
                />
            </div>
    )


}
