import styles from "./TagsBlock.module.css";
import React, {useState} from "react";
import stilesLight from "stylesLight.module.css"

export function TagItem({tag, isActive = false, onClick = (d) => d}) {
    const [active, setActive] = useState(isActive);
    return <div key={tag.id} onClick={() => {
        onClick();
        setActive(!active);
    }}
                className={styles.tag + " " + stilesLight.doit + " " + (active ? styles.activeTag : "")}>{tag.name}</div>
}
