import React from "react";
import styles from "./Projects.module.css"
import {ProjectsList} from "components/lists/ProjectList";
import stilesLight from "stylesLight.module.css";


export function Projects() {
    return <>
        <div className={styles.contentLayer}>
            <div className={stilesLight.title}>
                Проекты
            </div>
            <div className={stilesLight.text}> На данной странице вы можете ознакомиться с кратким описанием
                проектных идей. Система тегов
                позволит вам легко и быстро найти интересующий вас проект.
                Для подачи проекта или заявки на участие в проекте вам необходимо пройти
                регистрацию/авторизацию.
                Ознакомиться с заявками, находящимися на этапе модерации или в процессе доработки, можно в
                разделе
                «Бездна».
            </div>
            <ProjectsList/>
        </div>
    </>
}


