import styles from "./MainBar.module.css";
import {NavLink} from "react-router-dom";
import React from "react";

export function BarLinkCustomer({icon, name, to, customer}) {
    return (
        <>

            {customer && <NavLink exact to={to} className={styles.link} activeClassName={styles.active}>
                <div className={styles.image}>
                    {icon}
                </div>
                <div>
                    {name}
                </div>
            </NavLink>}
            {!customer && <div className={styles.blocked_link}>
                <div className={styles.image}>
                    {icon}
                </div>
                <div>
                    {name}
                </div>
            </div>
            }

        </>
    )
}
