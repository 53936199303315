import React from 'react';
import styles from './TagBlock.module.css'
import stylesLight from "../../stylesLight.module.css"

type PropsType = {
    text: string,
    color?: string
}
export const Tag: React.FC<PropsType> = ({text, color = undefined}) =>
        (
                <div className={styles.tag}>
                    <div>•</div>
                    <div className={styles.tag_name + " " + stylesLight.doit}
                         style={color ? {backgroundColor: color} : {}}>{text}</div>
                </div>
        )
