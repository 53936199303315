import React, {useEffect, useState} from "react";
import defaultStyles from "../../defaultPage.module.css";
import {api_connector} from "../../../../../redux/api/UserApi";
import {UserItem} from "./UserItem";
import {Loader} from "../../../../../components/uiKit/Loader";
import {v4 as getUUID} from "uuid";
import {Button} from "../../../../../components/uiKit/btns/Button";
import {uniqueIdObjects} from "../../../../../features/utils";
import {GroupType, TeacherStudentType} from "types/UserTypes";


const type = "group"
const mixedGroupName = "Все группы"

type MixedGroupType = {
    id: string,
    name: typeof mixedGroupName
}

const addMixedGroup: (groups: Array<GroupType>) => Array<GroupType | MixedGroupType> = (groups: Array<GroupType>) => [{
    id: getUUID(),
    name: mixedGroupName
}, ...groups]


export function DivisionByStudent() {
    const [groupName, setGroupName] = useState<string | undefined>(undefined)
    const [users, setUsers] = useState<Array<TeacherStudentType>>([]);
    const [groups, setGroups] = useState<Array<GroupType | MixedGroupType>>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api_connector.getStudentsAllInfo(type).then(d => {
            const uniqueObjects = uniqueIdObjects<GroupType>(d.map(u => u.group))
            setGroups(addMixedGroup(uniqueObjects));
            if (groupName) {
                setUsers(d.filter(u => u.group.name === groupName));
            } else {
                setUsers(d)
            }
            setLoading(false);
        });
    }, [groupName])


    return (<div>
        {groups && <div className={defaultStyles.block} style={{marginBottom: "1rem"}}>
            {groups && groups.filter(g => g.name).map(g => <Button key={g.id}
                                                                   className={defaultStyles.group + " "
                                                                   + (groupName === g.name
                                                                   || (!groupName && g.name === mixedGroupName) ? defaultStyles.group_clicked : "")}
                                                                   onClick={() => setGroupName(g.name === mixedGroupName ? undefined : g.name)}>
                        {g.name}
                    </Button>
            )}
        </div>}
        {isLoading && <Loader/>}
        {!isLoading && <div>
            {users.length > 0 && users?.map(u => <UserItem key={u.id} header={u.full_name}
                                                           projects={u.projects}/>)}
        </div>}

    </div>)
}
