import React from "react";
import styles from './MainBar.module.css'

import BarLink from "./BarLink";
import {Info} from "../../icons";


export default function MainBar() {
    return (
        <div className={styles.list}>
            <BarLink to="/cabinet" name="Личная информация" icon={Info()}/>
            {/*<BarLink to="/cabinet/settings" name="Настройки" icon={PersonalInfo()}/>*/}
            {/*<BarLink to="/cabinet/contacts" name="Контакты" icon={Contacts()}/>*/}
            {/*<BarLink to="/cabinet/faq" name="Q&A" icon={Faq()}/>*/}
        </div>
    )
}
