import React from "react";
import stylesLight from 'stylesLight.module.css'
import styles from "./btns.module.css"
import {Button} from "./Button";


type PropsType = {
    onClick?: ((e: React.MouseEvent<Element, MouseEvent>) => void)
}

export function HeaderBtn(props: PropsType) {
    const {
        onClick = () => {
        }
    } = props;
    return <Button onClick={onClick} className={styles.small_btn + " " + stylesLight.button_text}>
        Войти
    </Button>
}
