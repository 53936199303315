import axios, {AxiosInstance} from "axios";
import {API_URL, FDS_URL} from "../../constants";
import {toast} from "react-toastify";
import {TeacherStudentType, UserMiniType, UserType} from "types/UserTypes";
import {ProjectKeyWordsListType, ProjectsListType, ProjectTagsListType, ProjectType} from "../../types/ProjectTypes";

class API {
    axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({withCredentials: true, timeout: 20000});
        this.axios.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;

            if (error.response && (error.response.status === 401 || error.response.status === 403) && !originalRequest._retry && error.config.url !== `${API_URL}/auth/jwt-refresh/`) {
                console.log('refreshToken');
                originalRequest._retry = true;

                // try {
                await api_connector.refreshToken()
                return axios(originalRequest)
                // } catch (e) {
                //     throw e;
                // }

            }

            return Promise.reject(error);
        });
    }

    async refreshToken() {
        return await this.axios.post(`${API_URL}/auth/jwt-refresh/`).catch(() => {
            console.error("Failed refreshing token")
            // throw e;
        })
    }


    async login(data: any) {
        return await this.axios.post(`${API_URL}/auth/login/`, data,)
    }

    async logout() {
        return await this.axios.post(`${API_URL}/auth/logout/`, undefined,).catch(() => {
            toast.error("Ошибка. Попробуйте позже.")
        });
    }

    async createUser(data: any) {
        return await this.axios.post(`${API_URL}/users/`, data,);
    }

    async getMe() {
        return await this.axios.get<UserType>(`${API_URL}/users/me/`,).then(r => r.data)
    }

    async updMe(data: any) {
        return await this.axios.patch<UserType>(`${API_URL}/users/me/`, data,).then(r => r.data)
    }

    async getProjects(status: number | undefined = undefined, tags: Array<number> = []): Promise<ProjectsListType> {
        return await this.axios.get<ProjectsListType>(`${API_URL}/projects/`, {
            params: {
                status: status,
                tags: tags
            }
        }).then(r => r.data).catch((e) => {
            toast.error("Ошибка. Попробуйте позже.")
            throw e
        });
    }

    async getProject(id: number | string) {
        return await this.axios.get<ProjectType>(`${API_URL}/projects/${id}/`,).then(r => r.data).catch((e) => {
            toast.error("Ошибка. Попробуйте позже.")
            throw e
        });
    }

    async getHistory(id: number | string) {
        try {
            const history = await this.axios
                .get<ProjectHistoryItem[]>(`${API_URL}/projects/${id}/history/`)
                .then((res) => res.data);

            return history;
        } catch (e) {
            toast.error("Ошибка. Попробуйте позже.");
            throw e;
        }
    }

    async getProjectByDate(projectId: string, date: string) {
        try {
            const history = await this.axios
                .get<ProjectType>(`${API_URL}/projects/${projectId}/history/?date=${date}`)
                .then((res) => res.data);

            return history;
        } catch (e) {
            toast.error("Ошибка. Попробуйте позже.");
            throw e;
        }
    }

    async getMyProjects(role: number) {
        return await this.axios.get<ProjectsListType>(`${API_URL}/project/me/`, {
            params: {
                role: role
            }
        })
                .then(r => r.data)
                .catch((e) => {
                    toast.error("Ошибка. Попробуйте позже.")
                    throw e
                });
    }

    async editProject(id: number | string, data: any) {
        return await this.axios.patch(`${API_URL}/projects/${id}/`, data).catch((e) => {
            toast.error("Ошибка. Попробуйте позже.")
            throw e
        });
    }

    async getTags() {
        return await this.axios.get<ProjectTagsListType>(`${API_URL}/tags/`,)
                .then(r => r.data)
                .catch((e) => {
                    toast.error("Ошибка. Попробуйте позже.");
                    throw e;
                });
    }

    async createTags(tags: any) {
        if (!tags) return;
        return await this.axios.post<ProjectTagsListType>(`${API_URL}/tags/`, tags,)
                .then(r => r.data)
                .catch((e) => {
                    toast.error("Ошибка. Попробуйте позже.")
                    throw e;
                });
    }

    async createKeyWords(key_words: any) {
        if (!key_words) return;
        return await this.axios.post<ProjectKeyWordsListType>(`${API_URL}/key-words/`, key_words,)
                .then(r => r.data)
                .catch((e) => {
                    toast.error("Ошибка. Попробуйте позже.")
                    throw e;
                });
    }

    async uploadFile(data: any, onUploadProgress: (event: any) => void | undefined) {
        return await this.axios.post(`${FDS_URL}/upload/`, data, {
            withCredentials: true,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: onUploadProgress,
            timeout: 0,
        });
    }

    async searchUser(data: string, status: number) {
        return await this.axios.get<Array<UserMiniType>>(`${API_URL}/users/search/?last_name=${data}&status=${status}`,).catch((e) => {
            if (e.response?.status !== 404)
                toast.error("Ошибка. Попробуйте позже.")
            throw e;
        });
    }

    async searchTeachers() {
        return await this.axios.get(`${API_URL}/users/teachers/`,).catch((e) => {
            toast.error("Ошибка. Попробуйте позже.")
            throw e
        });
    }

    async submitProject(data: any) {
        return await this.axios.post(`${API_URL}/projects/`, data,)
    }

    async getFAQ() {
        return await this.axios.get(`${API_URL}/faq/`)
    }


    async getStudentsAllInfo(type: 'project' | 'group', name: string | undefined = undefined) {
        // if (name !== undefined) {
        //     return await this.axios.get(`${API_URL}/users/my_students/?group_by=${type}&name=${name}`)
        // }
        // return await this.axios.get(`${API_URL}/users/my_students/?group_by=${type}`)
        return await this.axios.get<Array<TeacherStudentType>>(`${API_URL}/users/my_students/`, {
            params: {
                type: type,
                name: name
            }
        })
                .then(r => r.data)
                .catch((e) => {
                    toast.error("Ошибка. Попробуйте позже.")
                    throw e
                });
    }

    async beMember(projectId: number | string) {
        const project = {
            project: projectId
        }
        return await this.axios.post(`${API_URL}/members/`, project)
    }

    async loseProject(projectId: number | string) {
        return await this.axios.delete(`${API_URL}/members/${projectId}/`)
    }

    async getCaptcha() {
        return await this.axios.post(`${API_URL}/captcha/`)
    }
}

export const api_connector = new API();

export const uploadFile = (onSave: { (u: any): any; (arg0: string): any; },
                           onUploadProgress: (event: any) => void | undefined,
                           isAvatar = false) =>
        (d: any) => {
            let formData = new FormData();
            if (isAvatar) {
                formData.append("file", d, 'UserProfile.jpg');
            } else {
                formData.append("file", d);
            }

            return api_connector.uploadFile(formData, onUploadProgress).then(
                    r => onSave(`${FDS_URL}/uploads/${r.data['file_id']}`)
            )
        }

