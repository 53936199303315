import React, {useState} from "react";
import style from './SubmitProject.module.css'
import {connect} from "react-redux";
import {
    SubmitProjectFirstFieldsType,
    SubmitProjectFirstForm
} from "../../../../components/forms/Customer/SubmitProjectFirstForm";
import {ThreeStepPanel} from "../../../../components/uiKit/stepPanel/StepPanel";
import {
    SubmitProjectSecondFieldsType,
    SubmitProjectSecondForm
} from "../../../../components/forms/Customer/SubmitProjectSecondForm";
import {
    SubmitProjectThirdFieldsType,
    SubmitProjectThirdForm
} from "../../../../components/forms/Customer/SubmitProjectThirdForm";
import {api_connector} from "../../../../redux/api/UserApi";
import {unique} from "../../../../features/utils";
import {FormSubmitHandler, reset} from 'redux-form'
import {useHistory} from "react-router-dom";
import {required, requiredArray} from "../../../../components/forms/validators";
import {toast} from "react-toastify";
import {AppState} from "../../../../redux/store";
import {UserType} from "../../../../types/UserTypes";


type PropsType = MapStateToPropsType & MapDispatchToPropsTypes & {}

type SubmitProjectValuesType =
        SubmitProjectFirstFieldsType
        & SubmitProjectSecondFieldsType
        & SubmitProjectThirdFieldsType

export function SubmitProjectPre(props: PropsType) {
    const [page, setPage] = useState(1);
    const [redPages, setRedPages] = useState<Array<number>>([]);
    const history = useHistory()

    function nextPage() {
        if (redPages.length > 0 && redPages.includes(page)) {
            delete redPages[redPages.indexOf(page)];
            setRedPages(redPages)
        }
        setPage(page + 1);
    }

    const requiredFields = [
        {
            "page": 1,
            "name": "tags",
            "validators": [requiredArray]
        },
        {
            "page": 1,
            "name": "name",
            "validators": [required]
        },
        {
            "page": 1,
            "name": "problem_need",
            "validators": [required]
        },
        {
            "page": 1,
            "name": "use_contour",
            "validators": [required]
        },
        {
            "page": 1,
            "name": "description",
            "validators": [required]
        },
    ]

    // const red_numbs = [];

    const submitProject: FormSubmitHandler<SubmitProjectValuesType> = async (data) => {
        // console.log(requiredFields['tags'][0](data['tags']))
        // let red_pages: Array<number> = [];
        let red_pages = unique<number>(requiredFields.map(f => {
            // @ts-ignore
            const val = data[f.name]
            if (f.validators.map(v => v(val)).some(er => !er))
                return f.page
            return undefined
        }).filter(p => p))
        // for (let i = 0; i < 5; i++) {
        //
        //     // if (requiredFields[i][1](data[requiredFields[i][0]]) !== undefined) {
        //     //     red_pages.push(requiredFields[i][2])
        //     // }
        // }

        console.log(red_pages)
        if (red_pages.length !== 0) {
            red_pages = unique(red_pages)
            setPage(red_pages[0])
            setRedPages(red_pages);
            toast.error("Заполните все поля для подачи проекта.")

            return
        }
        setRedPages([]);
        let tags: Array<number> = []
        const newTags = data.tags?.filter((t) => t?.__isNew__).map((t) => ({name: t.label})) ?? []
        if (newTags.length) {
            const createdTagsId = await api_connector.createTags(newTags)
            if (createdTagsId) {
                tags = createdTagsId.map(t => t.id)
            }
        }

        // console.log(createdTagsId)
        const existsTags = data?.tags?.filter((t) => !t?.__isNew__).map((t) => t.value)
        if (existsTags?.length) {
            // tags.
        }
        // console.log(data, '1');
        const dataToBack =
                {
                    requirements: [],
                    ...data,
                    "tags": [...tags,
                        ...(existsTags && existsTags.length > 0 ? (existsTags?.filter((t) => t) ?? []) : [])
                    ],
                    // "tutors": (data.tutors && data?.tutors.length > 0) ? unique(data?.tutors?.map((t) => t.tutors?.value) ?? []).filter((t) => t) ?? [] : [],
                    "tutors": (data.tutors && data?.tutors.length > 0) ? data?.tutors.map((t) => t.tutors?.value) : [],
                    "files": (data.files && data.files.length > 0) ? data.files?.map(f => ({
                        "name": f.fileName,
                        "link": f.link,
                        "is_link": f.type === '1'
                    })) ?? [] : []
                }
        // console.log(dataToBack, '2');
        api_connector.submitProject(dataToBack).then((r) => r.data).then(() => {
            props.clearForm();
        }).then(() => history.push('/cabinet/customer/projects')).then(() => toast.info('Ваш проект отправлен на модерацию. Вы можете следить за его статусом в личном кабинете в разделе «Мои проекты».'));

    }

    console.log("Page", page)
    return (
            <div className={style.itemsContainer}>
                <ThreeStepPanel step={page} changePage={(page: number) => setPage(page)} red_numbs={redPages}/>
                {page === 1 && <SubmitProjectFirstForm onSubmit={nextPage}/>}
                {page === 2 && <SubmitProjectSecondForm onSubmit={nextPage}/>}
                {page === 3 && <SubmitProjectThirdForm onSubmit={submitProject}/>}
                {/*{page === 4 && <SubmitProjectFourthForm onSubmit={nextPage}/>}*/}
                {/*{page === 5 && <SubmitProjectFivesForm onSubmit={(v) => submitProject(v)}/>}*/}
            </div>
    )
}

type MapStateToPropsType = {
    user?: UserType
}
type MapDispatchToPropsTypes = {
    clearForm: () => void
}

export const SubmitProject = connect((state: AppState): MapStateToPropsType => ({
    user: state.user?.user
}), (dispatch): MapDispatchToPropsTypes => ({
    clearForm: () => dispatch(reset('customer-submit-project'))
}))(SubmitProjectPre);
