import styles from "./ProjectItem.module.css";
import React from "react";
import {Link} from "react-router-dom";
import stylesLight from "../../stylesLight.module.css"
import stylesSemiBold from "../../stylesSemibold.module.css"
import {ProjectTagType} from "../../types/ProjectTypes";

type PropsType = {
    id: number,
    name: string,
    description: string,
    opened: boolean
    tags?: Array<ProjectTagType>
}


export function ProjectItem(props: PropsType): JSX.Element {
    const {id, name, description, tags = [], opened} = props

    return (
            <div key={`Project_${id}`} className={styles.item}>
                <div className={styles.content}>
                    <Link to={`/project/${id}`} className={stylesSemiBold.text + " " + styles.title}>{name}</Link>
                    <div className={styles.tag_block}>
                        {tags.map(i => (
                                <div key={i.id} className={styles.tag + " " + stylesLight.small}>
                                    <span/>
                                    <div>{i.name}</div>
                                </div>
                        ))}
                    </div>
                    <div className={styles.subtitle + " " + stylesLight.doit}>
                        {description}
                    </div>
                    <div className={styles.link}>
                        <Link to={"/project/" + id} className={stylesLight.doit}>Подробнее</Link>
                        <div className={stylesLight.doit}>Набор: {opened ? "Открыт" : "Закрыт"}</div>
                    </div>
                </div>

            </div>
    )
}

// ProjectsList.propTypes = {
//     id: PropTypes.number,
//     name: PropTypes.string,
//     description: PropTypes.string,
//     tags: PropTypes.arrayOf(PropTypes.string)
// }
