import React, {useEffect, useState} from "react";
import styles from "./RegInfo.module.css";
import stylesLight from "../../stylesLight.module.css";
import icon from "../../icons/exl.svg";
import Linkify from "react-linkify";
import {api_connector} from "../../redux/api/UserApi";
import {CustomLink} from '../../components/uiKit/links/CustomLink'
import {Loader} from "../../components/uiKit/Loader";
import Roles from "./RefInfoPages/Roles";
import {Collapse} from "../../components/uiKit/collapse/Collapse";

export function RefInfo() {
    const [openedPage, setopenedPage] = useState(1);
    const [FAQ, setFAQ] = useState(false);
    useEffect(() => {
        api_connector.getFAQ().then(r => r.data).then(d => {
            setFAQ(d);
        })
    }, [])

    return (
        <div className={styles.container}>
            <h1 className={stylesLight.title}>Справочная информация</h1>
            <div className={styles.text + " " + stylesLight.text}>Данный раздел нашего сайта носит информационный
                характер. Мы надеемся, что
                представленная ниже информация
                поможет проектным командам в достижении поставленных целей.
            </div>
            <div className={styles.block}>
                {["Роли в проекте", "Полезные ресурсы", "Таймлайн 2021", "FAQ"].map((el, index) =>
                    <button key={index}
                            className={styles.point_name + " " + stylesLight.text + " " + (openedPage === index + 1 ? styles.active : "")}
                            onClick={() => {
                                setopenedPage(index + 1)
                            }}>{el}
                    </button>)}
            </div>
            {openedPage === 1 && <Roles/>}
            {openedPage === 2 && <div>
                <h2 className={styles.subtitle + " " + stylesLight.subtitle} style={{marginTop: "3rem"}}>Материалы:</h2>
                <div className={styles.text + " " + stylesLight.text} style={{marginTop: "2rem"}}><CustomLink
                    link={'https://www.youtube.com/playlist?list=PLaj8t0HlLEnzV1ROmyBIPp2Ex0_ItDdzZ'}>Видео-лекции</CustomLink> в
                    рамках курса «Основы проектной
                    деятельности». <br/>
                    Методические материалы ММФ:
                    <ul style={{marginTop: "0rem"}}>
                        {/*<li>презентации;</li>*/}
                        <li>по <CustomLink noBlank
                                           link={'https://www.nsu.ru/upload/medialibrary/5be/Методические рекомендации по планированию и выбору идей проекта.pdf'}>планированию
                            и выбору идей проекта</CustomLink>;
                        </li>
                        <li>по <CustomLink noBlank
                                           link={'https://www.nsu.ru/upload/medialibrary/6ac/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5%20%D1%80%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D0%B8%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B5%20%D1%83%D1%81%D1%82%D0%B0%D0%B2%D0%B0%20%D0%B8%D0%BB%D0%B8%20%D0%B7%D0%B0%D1%8F%D0%B2%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%B3%D1%80%D0%B0%D0%BD%D1%82%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D1%8B%20%D0%B4%D0%BB%D1%8F%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0.pdf'}>подготовке
                            устава или заявки на грантовый конкурс для получения поддержки проекта</CustomLink>;
                        </li>
                        <li>по <CustomLink noBlank
                                           link={'https://www.nsu.ru/upload/medialibrary/0a6/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5%20%D1%80%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D0%B8%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B5%20%D0%B8%20%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8E%20%D0%BF%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D0%B8.pdf'}>подготовке
                            и представлению презентации в рамках прохождения учебных дисциплин и
                            выступления с выпускной квалификационной работой.</CustomLink>
                        </li>
                    </ul>
                </div>
                <div className={styles.text + " " + stylesLight.text} style={{marginTop: "2rem"}}>Полезные ресурсы
                    Академпарка новосибирского
                    Академгородка:
                    <ul style={{marginTop: "0rem"}}>
                        <li><CustomLink link={'https://academpark.com/projects/biznes_inkubator/'}>Бизнес-инкубатор
                            Академпарка</CustomLink>;
                        </li>
                        <li><CustomLink link={'https://academpark.com/projects/a_start/'}>Бизнес-ускоритель
                            А:СТАРТ</CustomLink>;
                        </li>
                        <li><CustomLink link={'https://academpark.com/projects/healthnet/'}>Инфраструктурный центр
                            HealthNet</CustomLink>;
                        </li>
                        <li><CustomLink link={'https://academpark.com/projects/konveerum/'}>Конвеерум</CustomLink>.</li>
                    </ul>
                </div>
                <div className={styles.text + " " + stylesLight.text} style={{marginTop: "2rem"}}>Различные фонды:
                    <ul style={{marginTop: "0rem"}}>
                        <li><CustomLink link={'https://old.asi.ru/projects/submit_a_project/'}>Агентство стратегических
                            инициатив</CustomLink>;
                        </li>
                        <li><CustomLink link={'https://fasie.ru/'}>Фонд содействия инновациям</CustomLink>;</li>
                        <li><CustomLink link={'https://xn--80afcdbalict6afooklqi5o.xn--p1ai/public/contest/index'}>Фонд
                            президентских грантов</CustomLink>;
                        </li>
                        <li><CustomLink link={'https://myrosmol.ru/'}>Росмолодежь</CustomLink>;</li>
                        <li><CustomLink link={'https://rscf.ru/project/'}>Российский научный фонд</CustomLink>.</li>
                    </ul>
                </div>
                <div className={styles.text + " " + stylesLight.text} style={{marginTop: "2rem"}}><CustomLink
                    link={'https://www.rfbr.ru/rffi/ru/'}>Российский фонд фундаментальных исследований.</CustomLink>
                </div>
                <div className={styles.text + " " + stylesLight.text} style={{marginTop: "0rem"}}><CustomLink
                    link={'https://www.youtube.com/playlist?list=PLaj8t0HlLEnxAIaOoBpJYGtDl3hi0snvs'}>Конкурс проектов
                    ММФ 2020</CustomLink>.
                </div>
                <h2 className={styles.subtitle + " " + stylesLight.subtitle}
                    style={{marginTop: "4rem"}}>Литература:</h2>
                <div className={styles.text + " " + stylesLight.text} style={{marginTop: "2rem"}}>
                    <ul style={{marginTop: "0rem"}}>
                        <li>Руководство к своду знаний по управлению проектами (PMBoK).</li>
                        <li>Обучение схематизации в школе: Сборник сценариев для проведения уроков и
                            тренингов/Учебно-методическое пособие для учащихся 10-11 классов. - М.: Пушкинский институт,
                            2005.
                        </li>
                        <li>Построение бизнес-моделей. Настольная книга стратега и новатора/И. Пинье — «Альпина
                            Диджитал», 2010 — (Сколково)
                        </li>
                        <li>Скрайбинг. Объяснять просто/П. Петровский, Н. Любицкий, М. Кутузова. – Москва: Издательство
                            «Э», 2016.
                        </li>
                        <li>Руководство по дизайн-мышлению.</li>
                    </ul>
                </div>
                <h2 className={styles.subtitle + " " + stylesLight.subtitle}
                    style={{marginTop: "4rem"}}>Онлайн–курсы:</h2>
                <div className={styles.text + " " + stylesLight.text} style={{marginTop: "2rem"}}>
                    <ul style={{marginTop: "0rem"}}>
                        <li><CustomLink link={'https://ru.coursera.org/learn/human-project-management'}>Курс «Принципы
                            управления людьми и проектами»</CustomLink>.
                        </li>
                    </ul>
                    Если вы студент НГУ, то пройти данный курс можно бесплатно в рамках программы Coursera for campus.
                    Для этого необходимо подать заявку в разделе «Дистанционное обучение» на сайте info.nsu.ru
                    <ul style={{marginTop: "1rem"}}>
                        <li><CustomLink link={'https://openedu.ru/course/hse/PROJWORK/'}>Курс «Основы проектной
                            деятельности» от НИУ ВШЭ</CustomLink>;
                        </li>
                        <li><CustomLink link={'https://openedu.ru/course/spbstu/OPD/'}>Курс «Основы проектной
                            деятельности» от ФГАОУ ВО «Санкт-Петербургский политехнический
                            университет Петра Великого»</CustomLink>;
                        </li>
                        <li><CustomLink link={'https://openedu.ru/course/urfu/PROJ/'}>Курс «Основы проектной
                            деятельности» от УрФУ</CustomLink>;
                        </li>
                        <li><CustomLink link={'https://openedu.ru/course/misis/PROJECT/'}>Курс «Управление проектами в
                            современной компании» от НИТУ; МИСиС</CustomLink>;
                        </li>
                        <li><CustomLink link={'https://openedu.ru/course/hse/PRMN/'}>Курс «Управление проектами» от НИУ
                            ВШЭ</CustomLink>.
                        </li>
                    </ul>
                </div>
            </div>}
            {openedPage === 3 && <div>
                <div className={styles.text + " " + stylesLight.text}>Ниже представлен таймлайн в рамках дисциплины
                    Основы проектной деятельности
                    2021-2022 учебного года.
                </div>
                <div>
                    <div className={styles.timelineBlock}>
                        <div className={styles.timelinePerCont}>
                            <div
                                className={styles.subsubtitle + " " + styles.timelinePer + " " + stylesLight.subtitle}>Сентябрь
                                -
                                октябрь 2021
                            </div>
                        </div>
                        <div className={styles.text + " " + styles.timelineTxt + " " + stylesLight.text}>Анализ
                            ситуации, проблематизация и
                            первичное целеполагание.
                            Результат – публичная презентация, содержащая: аналитику, формулировку проблемы/потребности,
                            информацию о целевой аудитории/заказчике проекта, формулировку проектной цели.
                        </div>
                    </div>
                    <div className={styles.timelineBlock}>
                        <div className={styles.timelinePerCont}>
                            <div
                                className={styles.subsubtitle + " " + styles.timelinePer + " " + stylesLight.subtitle}>Ноябрь
                                2021
                            </div>
                        </div>
                        <div className={styles.text + " " + styles.timelineTxt + " " + stylesLight.text}>Формулировка и
                            тестирование гипотезы
                            решения, оформление предварительного варианта устава проекта.
                            Результат – публичная презентация и предварительный вариант устава проекта. Предзащита
                            проектов ориентировочно состоится 29 ноября – 3 декабря.
                        </div>
                    </div>
                    <div className={styles.timelineBlock}>
                        <div className={styles.timelinePerCont}>
                            <div
                                className={styles.subsubtitle + " " + styles.timelinePer + " " + stylesLight.subtitle}>Декабрь
                                2021
                            </div>
                        </div>
                        <div className={styles.text + " " + styles.timelineTxt + " " + stylesLight.text}>Оформление
                            проекта.
                            Результат – согласованный устав проекта. Если проект требует привлечения финансирования, то
                            указывается конкретный источник (приводятся подтверждения) или методы привлечения
                            финансирования.
                        </div>
                    </div>
                    <div className={styles.timelineBlock}>
                        <div className={styles.timelinePerCont}>
                            <div
                                className={styles.subsubtitle + " " + styles.timelinePer + " " + stylesLight.subtitle}>Февраль
                                - май 2022
                            </div>
                        </div>
                        <div className={styles.text + " " + styles.timelineTxt + " " + stylesLight.text}>Реализация
                            проекта. Предзащита проектов
                            в рамках 2 семестра ориентировочно состоится в середине апреля.
                            Результат - реализованный проект, оформленный отчет, подготовленная и представленная
                            презентация.
                        </div>
                    </div>
                </div>
                <div className={styles.marginBlock} style={{display: "flex", flexDirection: "row"}}>
                    <div>
                        <img src={icon} alt={'!'} style={{flex: 1}}/>
                    </div>
                    <div className={styles.text + " " + stylesLight.text}
                         style={{paddingLeft: "0.7rem", marginTop: "0rem"}}>Запрещается менять
                        проект после середины апреля 2022 г. (предзащиты в рамках 2 семестра) в рамках дисциплины ОПД.
                        При смене проекта команде необходимо пройти все стадии заново.
                    </div>
                </div>
            </div>}
            {openedPage === 4 && <div className={styles.marginBlock}>
                {FAQ && FAQ.map((i, index) =>
                    <div style={{borderBottom: "1px solid #000"}}>
                        <Collapse key={index} title={<div className={stylesLight.subtitle}>{i.question}</div>}>
                            <div className={stylesLight.text + " " + styles.faq}><Linkify> {i.answer} </Linkify></div>
                        </Collapse>
                    </div>
                )}
                {!FAQ && <Loader/>}
            </div>}
        </div>
    )
}
