import React, { ComponentProps } from "react";
import styles from "./input.module.css"
import SwitchSelector from "react-switch-selector";
import {WrappedFieldProps} from "redux-form";
import {ProjectStatusType} from "../../types/ProjectTypes";

type OptionType = ComponentProps<typeof SwitchSelector>['options'][number]

function getInitialValue(status: ProjectStatusType) {
    switch (status) {
        case 1:
            return status - 1
        case 2:
            return status - 1
        case 3:
            return 0
        case 4:
            return status - 2
        case 5:
            return status - 2
        case 6:
            return status - 2
        default:
            return 0
    }
}


type PropsType = WrappedFieldProps & {
    options: Array<OptionType>
    disabled?: boolean
    bgColor?: string
    defaultColor?: string
    customOnChange?: (d: number) => void
    fontColor?: string
    isStatusEdit?: boolean
}
export const SelectorInput: React.FC<PropsType> = ({
                                                       options,
                                                       disabled = false,
                                                       defaultColor,
                                                       input,
                                                       bgColor = undefined,
                                                       customOnChange = () => {
                                                       },
                                                       fontColor = undefined,
                                                       isStatusEdit = false
                                                   }) => {

    return (
            <div>
                {!isStatusEdit && <div className={styles.Switcher1}>
                    <SwitchSelector
                            {...input}
                            onChange={(d: any): void => {
                                input.onBlur(d);
                                input.onChange(d);
                                customOnChange(d)
                            }}
                            options={options}
                            initialSelectedIndex={(input.value - 1) || 0}
                            selectedBackgroundColor={defaultColor}
                            backgroundColor={bgColor}
                            disabled={disabled}
                            fontColor={fontColor}
                    />
                </div>}
                {isStatusEdit && <div className={styles.Switcher2}>
                    <SwitchSelector
                            {...input}
                            onChange={(d: any): void => {
                                input.onBlur(d);
                                input.onChange(d);
                                customOnChange(d)
                            }}
                            options={options}
                            initialSelectedIndex={getInitialValue(input.value) || 0}
                            selectedBackgroundColor={defaultColor}
                            backgroundColor={bgColor}
                            disabled={disabled}
                            fontColor={fontColor}
                            wrapperBorderRadius={15}
                            optionBorderRadius={15}
                            fontSize={window.innerWidth > 600 ? 14 : 11}
                    />
                </div>}
            </div>


    )

}
