import React from 'react';
import styles from './Project.module.css'
import Linkify from 'react-linkify';
import stylesLight from "../../stylesLight.module.css"

type PropsType = {
    description: string
}
export const Description: React.FC<PropsType> = ({description}) => {
    return (
            <div className={styles.description + " " + stylesLight.text}>
                <Linkify>
                    {description}
                </Linkify>
            </div>
    )
}
