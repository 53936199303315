// import Helmet from "react-helmet";
// import React from "react";
// import ogImage from "icons/pic2.svg"

import {CustomerJobType} from "../types/UserTypes";
import {FieldArrayFieldsProps} from "redux-form";

export function unique<T = any>(ar: Array<T | undefined>): Array<T> {
    if (!ar) return []
    let j = new Map<string, any>();

    ar.forEach(function (v) {
        if (j) {
            j.set(v + '::' + typeof (v), v)
        }
    });

    return Object.keys(j).map(function (v) {
        return j.get(v);
    });
}

interface ObjectWithIdType {
    id: string | number
}

export function uniqueIdObjects<T extends ObjectWithIdType>(arr: Array<T | undefined>): Array<T> {
    if (!arr)
        return []
    const map = new Map<string | number, any>();

    arr.filter(v => v).forEach(v => v && map.set(v.id, v))

    return Object.keys(map).map(v => map.get(v));
}


export function getDescription(desc: FieldArrayFieldsProps<CustomerJobType>): string {
    return desc.getAll().filter(d => d.organization && d.position)?.map(d => `${d.position}, ${d.division || ""} ${d.organization}`).join('; ') ?? "";
}


// export function MetaTags(props) {
//     return <Helmet>
//         <title>{props.title}</title>
//         <meta property="og:image" content={`${window.location.origin}${ogImage}`}/>
//         <meta property="og:title" content={props.title}/>
//         <meta property="og:description" content={props.description}/>
//         <meta property="og:type" content="article" />
//     </Helmet>
// }
//
// MetaTags.propTypes = {
//     title: String,
//     description: String,
// }
//
// MetaTags.defaultProps = {
//     title: "Проекты ММФ",
//     description: "На данной странице вы можете ознакомиться с кратким описанием проектных идей."
// }
