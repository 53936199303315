import React from "react";
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";
import {AppState} from "redux/store";
import {RouteProps} from "react-router";

type PropsType = RouteProps<string> & {
    type: "guest" | "private"
    isAuthUser: boolean
    redirectTo?: string
}


const AuthRoutePre: React.FC<PropsType> = props => {
    const isAuthUser = props.isAuthUser;
    const redirectTo = props.redirectTo
    const {type} = props;
    if (type === "guest" && isAuthUser) return <Redirect to={redirectTo || "/"}/>;
    else if (type === "private" && !isAuthUser) return <Redirect to={redirectTo || '/auth/login_type'}/>;

    return <Route {...props} />;
};

export const AuthRoute = connect((state: AppState) => ({
    isAuthUser: state.user.isLoggedIn,
    isLoading: state.user.isLoading
}))(AuthRoutePre)


