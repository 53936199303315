import React from "react";

export function Application() {

    return <svg width="27" height="27" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.72562 21.1056V22.3954C8.72562 22.7179 8.98535 23 9.28219 23H13.5121H13.6977C13.9945 23 14.2542 22.7179 14.2542 22.3954V20.8638C14.2542 19.3725 14.7366 17.8812 15.1819 16.8333C15.3674 16.3496 15.6271 15.9063 15.924 15.5032C16.5918 14.4956 17 13.2864 17 11.9967C17 8.49009 14.18 5.66872 10.8777 6.03147C8.28036 6.35391 6.20249 8.69162 6.01697 11.513C5.90565 13.0043 6.35091 14.415 7.09301 15.5435C7.35274 15.9466 7.61247 16.3899 7.798 16.8333C8.24326 17.8812 8.72562 19.4128 8.72562 20.9041V21.1056ZM13.5121 20.3802C13.5121 20.4205 13.475 20.4608 13.4379 20.4608H9.54193C9.50482 20.4608 9.46772 20.4205 9.46772 20.3802C9.46772 20.1383 9.43061 19.8965 9.39351 19.6547C9.39351 19.6547 9.39351 19.6144 9.43061 19.6144H13.5492C13.5492 19.6144 13.5864 19.6144 13.5864 19.6547C13.5492 19.8965 13.5121 20.1383 13.5121 20.3802ZM9.57903 22.073V21.3475C9.57903 21.3072 9.57903 21.3072 9.61614 21.3072H13.475C13.5121 21.3072 13.5121 21.3072 13.5121 21.3475V22.073C13.5121 22.1133 13.5121 22.1133 13.475 22.1133H9.61614C9.57903 22.1133 9.57903 22.073 9.57903 22.073ZM15.4416 14.8583C15.4045 14.9389 15.3303 15.0196 15.2932 15.1002C15.0334 15.5032 14.7737 15.866 14.5882 16.3093C14.4769 16.5512 14.3656 16.8736 14.2542 17.196C14.0687 17.6797 13.9203 18.2037 13.7719 18.7679C13.7719 18.8083 13.7348 18.8082 13.7348 18.8082H9.28219C9.24509 18.8082 9.24509 18.8083 9.24509 18.7679C9.09667 18.2037 8.94825 17.6797 8.76272 17.196C8.65141 16.8736 8.5401 16.5915 8.42878 16.3093C8.24326 15.866 7.98352 15.4629 7.72379 15.1002C7.68668 15.0196 7.61247 14.9389 7.57537 14.8583C6.94459 13.8507 6.64775 12.5609 6.83327 11.1905C7.13011 9.01406 8.68851 7.28094 10.6922 6.91819C13.6606 6.35391 16.2579 8.85284 16.2579 11.9967C16.2208 13.0446 15.924 14.0119 15.4416 14.8583Z"
            fill="white"/>
        <path
            d="M14.5589 12C14.3384 12 14.1913 11.7972 14.1913 11.5944C14.1546 9.4041 12.28 8.87681 12.28 8.83625C12.0594 8.79569 11.9492 8.55232 12.0227 8.30895C12.0594 8.06559 12.28 7.94391 12.5005 8.02503C13.3459 8.26839 14.9632 9.32298 15 11.5538C14.9265 11.7972 14.7795 12 14.5589 12Z"
            fill="white"/>
        <path
            d="M20.5806 18C20.5071 18 20.4336 17.9617 20.3601 17.9235L17.1988 15.8191C17.0151 15.7044 16.9415 15.3983 17.0518 15.207C17.1621 15.0157 17.4562 14.9391 17.6399 15.0539L20.8012 17.1583C20.9849 17.273 21.0585 17.5791 20.9482 17.7704C20.8747 17.9617 20.7276 18 20.5806 18Z"
            fill="white"/>
        <path
            d="M17.426 8C17.2796 8 17.1332 7.92272 17.06 7.8068C16.9502 7.61359 16.9868 7.30447 17.2064 7.18854L20.3544 5.0633C20.5374 4.94738 20.8302 4.98602 20.94 5.21786C21.0498 5.41107 21.0132 5.72019 20.7936 5.83612L17.6456 7.96136C17.5724 8 17.4992 8 17.426 8Z"
            fill="white"/>
        <path
            d="M22.6043 12H18.4317C18.2158 12 18 11.7917 18 11.5C18 11.2083 18.1799 11 18.4317 11H22.5683C22.7842 11 23 11.2083 23 11.5C23 11.7917 22.8201 12 22.6043 12Z"
            fill="white"/>
        <path
            d="M2.42601 18C2.27959 18 2.13317 17.9227 2.05996 17.8068C1.95015 17.6136 1.98675 17.3045 2.20638 17.1885L5.35436 15.0633C5.53739 14.9474 5.83022 14.986 5.94004 15.2179C6.04985 15.4497 6.01324 15.7202 5.79362 15.8361L2.64564 17.9614C2.57243 18 2.49922 18 2.42601 18Z"
            fill="white"/>
        <path
            d="M5.58061 8C5.50709 8 5.43357 7.96174 5.36005 7.92348L2.19885 5.81914C2.01505 5.70436 1.94154 5.39828 2.05181 5.20697C2.16209 5.01567 2.45615 4.93915 2.63994 5.05393L5.80115 7.15827C5.98495 7.27305 6.05846 7.57913 5.94819 7.77044C5.83791 7.92348 5.69088 8 5.58061 8Z"
            fill="white"/>
        <path
            d="M4.55556 12H0.444445C0.222222 12 0 11.7917 0 11.5C0 11.2083 0.185185 11 0.444445 11H4.55556C4.77778 11 5 11.2083 5 11.5C5 11.7917 4.77778 12 4.55556 12Z"
            fill="white"/>
        <path
            d="M14.3534 5C14.2932 5 14.2632 5 14.203 4.96574C14.0226 4.86295 13.9624 4.62313 14.0226 4.45182L15.3459 1.23126C15.4361 1.0257 15.6466 0.957173 15.797 1.0257C15.9774 1.12848 16.0376 1.36831 15.9774 1.53961L14.6541 4.76017C14.594 4.93148 14.4737 5 14.3534 5Z"
            fill="white"/>
        <path
            d="M9.51594 5C9.34301 5 9.17009 4.91816 9.08363 4.71358L7.05175 0.662739C6.92205 0.417234 7.05175 0.130811 7.2679 0.0489758C7.52729 -0.0737769 7.82991 0.0489758 7.91637 0.253564L9.94825 4.3044C10.0779 4.54991 9.94825 4.83633 9.7321 4.91816C9.64563 5 9.55917 5 9.51594 5Z"
            fill="white"/>
    </svg>
}