import React from "react";
import styles from './MainBar.module.css'

import BarLink from "./BarLink";
import {BarLinkCustomer} from "./BarLinkCustomer";
import {PersonalInfo} from "../../icons";
import {connect} from "react-redux";
import {Iform} from "../../icons/iform";
import {Application} from "../../icons/Application";

function customerBarPre(props) {

    const customer = props.customer.user.customer;
    return (
        <div className={styles.list}>
            <BarLink to="/cabinet/customer/info" name="Анкета" icon={Iform()}/>
            <BarLinkCustomer to="/cabinet/customer/submit_project" name="Подать проект"
                             customer={customer} icon={Application()}/>
            <BarLinkCustomer to="/cabinet/customer/projects" name="Мои проекты" customer={customer}
                             icon={PersonalInfo()}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    customer: state.user ?? undefined,
})

export default connect(mapStateToProps)(customerBarPre)
