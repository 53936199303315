import React, { useState } from 'react';
import { push as Menu } from 'react-burger-menu';
import './LeftBar.css'
import { CabinetBar } from "./CabinetBar";
import styles from "../../components/header/HeaderMenu.module.css";
import Avatar from "@mui/material/Avatar";
import { connect, useDispatch } from "react-redux";
import { logoutUserThunk } from "../../redux/reducers/userReducer";
import CrossButton from '../../icons/ExitCross.svg'
import stylesSemibold from '../../stylesSemibold.module.css'

function getColor() {

    const colors = ['#5BC8AC', '#F18D9E', '#9E9DFF', '#FFCCAC', '#B2DBD5', '#FFCCAC', '#2B616D', '#DE8BD9', '#C6EEF5'];
    const index = Math.floor(Math.random() * colors.length);
    return colors[index]
}

function LeftBarPre(props) {
    const user = props.user;
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <Menu isOpen={isOpen} pageWrapId={'page-wrap'} outerContainerId={'outer-container'}
                onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
                <>
                    <div className={styles.container}>
                        <img src={CrossButton} onClick={() => setIsOpen(false)} className={styles.crossButton}
                            alt={'cross'} />
                        {user.user && <div className={styles.personName}>
                            <div className={styles.person}>
                                <div className={styles.img}>
                                    <Avatar className={styles.letter} style={{ borderRadius: "0.625rem" }}
                                        sx={{ bgcolor: getColor() }}
                                        alt={user.user?.last_name + " " + user.user?.first_name}
                                        src={user.user.image}>
                                        {user.user?.first_name[0] + user.user?.last_name[0]}
                                    </Avatar>
                                </div>
                                <div className={styles.block}>
                                    <div
                                        className={styles.titleName + " " + stylesSemibold.name}>{user.user?.last_name + " " + user.user?.first_name[0] + ". " + (user.user.middle_name ? (user.user.middle_name[0] + '.') : "")}</div>
                                </div>
                            </div>
                        </div>}
                        <CabinetBar onClose={() => setIsOpen(false)} />
                        <div className={styles.logout + " " + stylesSemibold.name}
                            onClick={() => dispatch(logoutUserThunk())}>Выйти
                        </div>
                    </div>
                </>
            </Menu>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,

})

export default connect(mapStateToProps)(LeftBarPre)