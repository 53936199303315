import React from 'react';
import styles from './StatusBar.module.css'
import stylesLight from "../../stylesLight.module.css"
import {ProjectStatusType} from "types/ProjectTypes";

type PropsType = {
    status: ProjectStatusType,
    status_name: string
}

function getClassName(status: ProjectStatusType) {
    switch (status) {
        case 1:
            return styles.moderate
        case 2:
            return styles.hole
        case 3:
            return styles.revision
        case 4:
            return styles.checked
        case 5:
            return styles.closed
    }
}

export const StatusBar: React.FC<PropsType> = ({status, status_name}) => {
    return (<div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <div className={styles.baseStatus + " " + getClassName(status) + " " + stylesLight.text}>
                    {status_name}
                </div>
            </div>

    )
}
