import React from "react";
import {Link} from "react-router-dom";
import defaultStyles from "../../defaultPage.module.css";
import {Collapse} from "components/uiKit/collapse/Collapse";
import {EllipseCollapseItem} from "components/uiKit/collapse/EllipseCollapseItem";
import {ProjectsMiniListType} from "types/ProjectTypes";

type PropsType = {
    header: string | JSX.Element
    projects?: ProjectsMiniListType
}

export const UserItem: React.FC<PropsType> = ({header, projects}) => {
    return (
            <EllipseCollapseItem>
                {projects ?
                        <Collapse title={header}>
                            <ul>
                                {
                                    projects.map(proj =>
                                            <li key={proj.id}>
                                                <Link className={defaultStyles.item} style={{marginBottom: 0}}
                                                      to={`/project/${proj.id}`}
                                                      key={proj.id}> {proj.name} </Link>
                                            </li>
                                    )
                                }
                            </ul>
                        </Collapse> :
                        <Collapse title={header}>
                            <div className={defaultStyles.item}> Пользователь не учавствует в проектах</div>
                        </Collapse>

                }
            </EllipseCollapseItem>
    )
}
