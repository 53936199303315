import React from "react";
import styles from './MainBar.module.css'

import BarLink from "./BarLink";
import {Iform} from "../../icons/teachergr";

export default function TeacherBar(props){
    return(
        <div className={styles.list}>
            <BarLink to="/cabinet/teacher/groups" name="Мои группы" icon={Iform()}/>
        </div>
    )
}
