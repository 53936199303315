import {Field, WrappedFieldArrayProps} from "redux-form";
import React, {useState} from "react";
import {v4 as getUUID} from 'uuid';
import {SimpleInput} from "./inputSimple";
import DeleteIcon from "../../icons/DeleteIcon.svg"
import styles from "./input.module.css"
import LinkIcon from 'icons/link_icon.png';
import DownloadIcon from 'icons/download_icon.png';
import Dropzone from "react-dropzone";
import {FileUploadModal} from "../modals/fileUploadModal";
import {LinkUploadModal} from "../modals/linkUploadModal";
import {Dropdown} from "semantic-ui-react";

type OwnPropsType = {
    name_suffix: string
    internal_type?: boolean
    label: string
    placeholderForAddBtn: string
}


type ArrayComponentProps = WrappedFieldArrayProps<MultiInputFileType>

export type MultiInputFileType = {
    id: string | number
    fileName: string
    originalName: string
    link: string
    type?: string
}

export const MultiInputFile: React.FC<ArrayComponentProps & OwnPropsType> =
        ({
             fields,
             label,
             placeholderForAddBtn,
             meta: {error, submitFailed}
         }) => {
            const [isVisible, setIsVisible] = useState(false);
            const [isVisibleLink, setIsVisibleLink] = useState(false);
            const [lastFile, setLastFile] = useState<File | undefined>(undefined);
            return <div>
                {/*{fields_input && fields_input.map((member, index) => {*/}
                {/*    fields*/}
                {/*    // console.log(fields.length)*/}
                {/*})}*/}
                {fields.map((member, index) => {
                    const {id, fileName, originalName, link, type} = fields.get(index);
                    console.log(id, fileName, originalName, link, type)
                    return (<div key={id} style={{display: "flex"}}>
                        <div style={{flex: 1}}>
                            {type === '1' &&
                            <Field type="text" component={SimpleInput} name={`${member}.fileName`} label={label}
                                   disabled
                                   defaultValue={`${fileName} ${originalName ? "(" + originalName + ")" : ""}`}/>

                            }
                            {type === '0' &&
                            <Field type="text" component={SimpleInput} name={`${member}.fileName`} label={label}
                                   disabled
                                   defaultValue={`${fileName} ${originalName ? "(" + originalName + ")" : ""}`}/>}

                        </div>
                        {type === '1' && <a className={styles.icon} href={link} target="_blank" rel="noreferrer">
                            <img src={LinkIcon} alt={`Перейти по ссылке`} className={styles.LinkImg}/>
                        </a>}
                        {type === '0' && <a className={styles.icon} href={link} target="_blank" rel="noreferrer">
                            <img src={DownloadIcon} alt={`Скачать файл`} className={styles.DownloadImg}/>
                        </a>}
                        <div className={styles.icon} onClick={() => fields.remove(index)}>
                            <img src={DeleteIcon} key={index} alt={`Удалить ${placeholderForAddBtn} ${index}`}
                            />
                        </div>
                    </div>);
                })
                }

                <FileUploadModal isOpen={isVisible} lastFile={lastFile} onClose={() => setIsVisible(false)}
                                 onSave={(d: any) => {
                                     fields.push({id: getUUID(), ...d})
                                     setLastFile(undefined);

                                 }}/>

                <LinkUploadModal isOpen={isVisibleLink} lastFile={lastFile} onClose={() => setIsVisibleLink(false)}
                                 onSave={(d: any) => {
                                     fields.push({id: getUUID(), ...d})
                                 }}/>

                <Dropzone onDrop={(d) => {
                    setLastFile(d[0]);
                    setIsVisible(true)

                }}>
                    {({getRootProps, getInputProps}) =>
                            <div {...getRootProps()} className={styles.DropContainer}>
                                <input {...getInputProps()} />
                                {/*<button type="button" className={styles.btn}>*/}
                                {/*    Добавить материалы*/}
                                {/*</button>*/}
                                <Dropdown simple item text="Добавить материалы"
                                          className={styles.dropdown}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setIsVisibleLink(true)}>Прикрепить
                                            ссылку</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setIsVisible(true)}>Прикрепить
                                            файл</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                    }
                </Dropzone>
                {submitFailed && error && <span>{error}</span>}
            </div>

        }
