import React from "react";

export function PersonIcon(props) {
    return (<svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.4 13.15C12.25 13.85 10.9 14.25 9.475 14.25C8.05 14.25 6.7 13.85 5.55 13.15C2.175 14.7 0 18.275 0 22.3C0 22.575 18.95 22.575 18.95 22.3C18.95 18.25 16.775 14.7 13.4 13.15Z"
                fill="white"/>
            <path
                d="M9.47461 13.25C13.1335 13.25 16.0996 10.2839 16.0996 6.625C16.0996 2.96611 13.1335 0 9.47461 0C5.81572 0 2.84961 2.96611 2.84961 6.625C2.84961 10.2839 5.81572 13.25 9.47461 13.25Z"
                fill="white"/>
        </svg>
    )
}