export const GREEN_CLR = 'var(--main-green-color)';
export const BG_CLR = '#33333311';
export const TEXT_CLR = '#333333';


export const StudentStatus = 1;
export const CustomerStatus = 2;
export const TutorStatus = 3;
export const TeacherStatus = 4;
export const ModeratorStatus = 5;

// export const API_URL="http://127.0.0.1/api";
// const BASE_URL = `http${document.URL.includes('https://') ? "s" : ""}://${document.location.host}`
export const API_URL = `/api/v1`;
export const FDS_URL = `/fds`;


export const PROFILE_PHOTO_QUALITY = 0.7
