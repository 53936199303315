import React, {useEffect} from "react";

import styles from './StudentInfoForm.module.css'

import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {SimpleInput} from "../../inputs";
import defaultStyles from "../../../screens/Cabinet/screens/defaultPage.module.css";
import {connect} from "react-redux";
import {MultiSelectorForTeachers} from "../../inputs/inputMultiSelectorForTeachers";
import stylesLight from "../../../stylesLight.module.css";
import {AppState} from "../../../redux/store";
import {CustomerType, StudentType} from "../../../types/UserTypes";
import {OptionType} from "types/ReactSelectTypes";

type StudentInfoType = {
    group_number: string
    teacher: OptionType
}

function StudentInfoFormPreRedux(props: InjectedFormProps<StudentInfoType, MapStateToPropsType> & MapStateToPropsType) {
    const {student, initialize} = props
    useEffect(() => {
        initialize({
                    group_number: student?.group.name ? student.group.name : "",
                    teacher: {
                        value: student?.group.teacher?.id ? student.group.teacher.id : "",
                        label: student?.group.teacher?.full_name ? student.group.teacher.full_name : "",
                    }
                }
        )
    }, [initialize, student])
    return (
            <form onSubmit={props.handleSubmit} className={styles.formContainer}>
                <div className={styles.itemsContainer}>
                    <div className={defaultStyles.title + " " + stylesLight.subtitle}>
                        Анкета cтудента
                    </div>

                    <div className={styles.subTitle2 + " " + stylesLight.doit}>
                        Номер вашей группы:
                    </div>
                    <Field type="text" component={SimpleInput} name='group_number' label='Номер группы'
                           disabled={!!student?.group}/>
                    <div className={styles.subTitle2 + " " + stylesLight.doit}>
                        Ваш преподаватель:
                    </div>
                    <Field type="text" component={MultiSelectorForTeachers} label={'Укажите вашего преподавателя'}
                           name={'teacher'} disabled={!!student?.group.teacher}/>

                    {/*<div className={styles.div}>*/}
                    {/*    <Button className={styles.butn}>*/}
                    {/*        Сохранить*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                    {/*<div className={styles.div}>*/}
                    {/*    <button type="submit" className={styles.butn}>*/}
                    {/*        Сохранить*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </form>
    )
}

type MapStateToPropsType = {
    customer?: CustomerType
    student?: StudentType
}

export const StudentInfoForm = connect((state: AppState): MapStateToPropsType => ({
    customer: state.user.user?.customer,
    student: state.user.user?.student
}),)(reduxForm<StudentInfoType>({form: 'customers-information'})(StudentInfoFormPreRedux))
