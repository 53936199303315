import React, {useCallback, useEffect, useRef, useState} from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import styles from './input.module.css'
import {PROFILE_PHOTO_QUALITY} from "../../constants";
import modalsStyles from "../modals/Modals.module.css"

export function ImagePicker(props) {
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({units: "px", aspect: 1, height: 250});
    const [completedCrop, setCompletedCrop] = useState(null);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    const onSave = () => {
        const canvas = previewCanvasRef.current;
        canvas.toBlob(props.onSave, "image/jpeg", PROFILE_PHOTO_QUALITY)
    }

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {!imgRef.current && <div className={modalsStyles.title}>
                Загрузка фото для аккаунта:
            </div>}
            {!imgRef.current && <div className={modalsStyles.subTitle}>
                Это фото будет отображаться на странице проекта в разделе “Команда”.
                Вы можете загрузить изображение в формате JPG, GIF или PNG.
            </div>}
            {imgRef.current && <div className={modalsStyles.title}>
                Фотография для аккаунта:
            </div>}
            {imgRef.current && <div className={modalsStyles.subTitle}>
                Выбранная область будет показываться на странице проекта.
            </div>}
            <ReactCrop

                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                className={styles.containerImage}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                minWidth={25}
            />

            {completedCrop?.width && <div style={{display: "none", flexDirection: "column", margin: "2rem"}}>
                Предпросмотр:
                <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    className={styles.canvas}
                />
            </div>}
            {!imgRef.current &&
            <div style={{marginTop: "2rem", marginBottom: "2rem", display: "flex", flexDirection: "column"}}>
                {/*<input type="file" accept="image/*" onChange={onSelectFile}/>*/}
                <input id="file-input" accept="image/*" type="file" onChange={onSelectFile}
                       style={{display: "none"}}/>
                {/*<div >*/}
                <label htmlFor="file-input" className={modalsStyles.btn} style={{marginTop: 0}}>
                    <span>Выберите файл</span>
                </label>
                {/*<img className={modalsStyles.fileImg} src="https://bmm.mca.nsu.ru/icons/screpka.svg" alt=""/>*/}
                {/*</div>*/}
            </div>}
            {imgRef.current && <button
                type="button"
                disabled={!completedCrop?.width || !completedCrop?.height}
                onClick={onSave}
                className={modalsStyles.btn}
                style={{marginTop: "2rem", marginBottom: "2rem", whiteSpace: "nowrap", width: "auto"}}

            >
                Сохранить и продолжить
            </button>}
        </div>
    );
}

// export const ImageForm = reduxForm({form: 'register-base'})(PicUploadNoCrop)
