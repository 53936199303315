import React from "react";
import styles from "../Projects/Projects.module.css"
// import defaultStyles from "../../index.css"
import {ProjectsList} from "../../components/lists/ProjectList";
import stylesLight from "../../stylesLight.module.css"


export default class Bezdna extends React.Component {
    // constructor() {
    //     super();
    //     this.state = {
    //         projects: [],
    //         tags: [],
    //         selectedProjects: []
    //     }
    // }
    //
    // componentDidMount(){
    //     api_connector.getProjects(2).then(r => r.data).catch((e) => {}).then(d => this.setState({...this.state, projects: d}))
    //     // api_connector.getTags().then(r => r.data).catch((e) => {}).then(d => this.setState({...this.state, tags: d}))
    // }

    render() {
        return (
            <div className={styles.contentLayer}>
                <div className={styles.title + " " + stylesLight.title}>Бездна</div>
                <div className={stylesLight.text}>Настоящий раздел содержит карточки проектов, находящихся в процессе
                    модерации или доработки. Здесь
                    вы можете ознакомиться с проектными идеями и даже записаться на определенный проект (необходима
                    авторизация в системе).
                </div>

                <ProjectsList status={2}/>
            </div>

        )
    }
}
