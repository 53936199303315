import React from "react";
import styles from './MainBar.module.css'
import {Iform} from "../../icons/iform";
import BarLink from "./BarLink";
import {PersonalInfo} from "../../icons";

export default function StudentBar() {
    return (
        <div className={styles.list}>
            <BarLink to="/cabinet/student/info" name="Анкета" icon={Iform()}/>
            <BarLink to="/cabinet/student/projects" name="Мои проекты" icon={PersonalInfo()}/>
        </div>
    )
}
