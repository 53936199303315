import React from "react";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import styles from './Register.module.css'
import {email, minLength8, required} from "./validators";
import {SimpleInput} from "../inputs";
import {Link} from "react-router-dom";
import {Button} from "../uiKit/btns/Button";

export type LoginFormType = {
    email: string
    password: string
}


function LoginFormPreRedux(props: InjectedFormProps<LoginFormType>) {
    // const history = useHistory()
    const {submitting, error} = props
    return (
            <form onSubmit={props.handleSubmit}>
                <div className={styles.block}>
                    <Field type="text" component={SimpleInput} name='email' label='Email' validate={[required, email]}/>
                    <Field type="password" component={SimpleInput} name='password' label='Пароль'
                           validate={[required, minLength8]}/>
                    {error && <strong className={styles.errorMessage}>{error}</strong>}
                    <Button type="submit" disabled={submitting} className={styles.btn}>
                    Войти
                </Button>
                {/*<button type="submit" onClick={() => history.push('/auth/register')} className={styles.btn + " " + styles.btn_register}>*/}
                {/*    Регистрация*/}
                {/*</button>*/}
                <div className={styles.links}>
                    <Link to='/auth/register'> Забыли свой логин или пароль? </Link>
                    <Link to='/auth/register'> Регистрация </Link>

                </div>

            </div>
        </form>
    )
}

export const LoginForm = reduxForm<LoginFormType>({form: 'login-base'})(LoginFormPreRedux)

