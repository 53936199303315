import React, {useEffect} from "react";
import styles from './PersonalInformation.module.css'
import {SimpleInput} from "../inputs";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {ImageInput} from "../inputs/imagePickerField";
import {Button} from "../uiKit/btns/Button";
import {UserType} from "types/UserTypes";

// const label = {inputProps: {'aria-label': 'Checkbox demo'}}; TODO: FIX IT

type PersonalInformationType = {
    image: string
    first_name: string
    last_name: string
    middle_name: string
    email: string
    telegram: string
    vk: string
}

type OwnProps = {
    user: UserType
}

function PersonalInformationPreRedux(props: InjectedFormProps<PersonalInformationType, OwnProps> & OwnProps) {
    const {invalid, submitting, pristine, user, anyTouched, initialize} = props;
    useEffect(() => {
        initialize({
            image: user.image,
            first_name: user.first_name,
            last_name: user.last_name,
            middle_name: user.middle_name,
            email: user.email,
            telegram: user.telegram,
            vk: user.vk,
        })
    }, [initialize, user])
    return (
            <form onSubmit={props.handleSubmit} className={styles.formContainer}>
                <div className={styles.itemsContainer}>
                    <div className={styles.block}>
                        <Field type="file" component={ImageInput} name='image' defaultValue={user.image}/>
                        {/*<FormControlLabel*/}
                        {/*    value="end"*/}
                        {/*    control={<Checkbox/>}*/}
                        {/*    label="End"*/}
                        {/*    labelPlacement="end"*/}
                        {/*/>*/}
                    </div>

                    <div className={styles.block}>
                        <Field type="text" component={SimpleInput} name='last_name' label='Фамилия' disabled
                               defaultValue={user.last_name}/>
                        <Field type="text" component={SimpleInput} name='first_name' label='Имя' disabled
                               defaultValue={user.first_name}/>
                        <Field type="text" component={SimpleInput} name='middle_name' label='Отчество'
                               defaultValue={user.middle_name} disabled={user.middle_name !== ""}/>
                        <Field type="text" component={SimpleInput} name='email' label='Email' disabled
                               defaultValue={user.email}/>
                        <Field type="text" component={SimpleInput} name='telegram' label='Telegram'
                               validate={[]} defaultValue={user.telegram}/>
                        <Field type="text" component={SimpleInput} name='vk' label='Ссылка VK'
                               validate={[]} defaultValue={user.vk}/>

                    </div>
                </div>

                <Button type="submit" disabled={!anyTouched || invalid || submitting || pristine}
                        className={styles.btn}>
                    Сохранить
                </Button>
            </form>
    )
}


export const PersonalInformationForm = reduxForm<PersonalInformationType, OwnProps>({form: 'personal-information'})(PersonalInformationPreRedux)
