import styles from "../RegInfo.module.css";
import stylesLight from "../../../stylesLight.module.css";
import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
    return <>
        <div className={styles.customer_inf}>
            <div className={styles.left_column}>
                <div className={styles.text_object + " " + stylesLight.text}><b style={{fontWeight: "600"}}>Заказчик
                    проекта</b> – это
                    человек, у которого возникла реальная проблема или потребность. Именно решениям таких
                    проблем и будет посвящена проектная деятельность.
                </div>
                <div className={styles.text_object + " " + stylesLight.text}>Заказчик может иметь образ
                    возможного решения, а также
                    контур его употребления. Но заказчик не обязан знать самого решения, иначе он давно нанял бы
                    команду инженеров или разработчиков и воплотил идеи в реальность.
                </div>
                <div className={styles.text_object + " " + stylesLight.text}>Заказчик может стать инвестором
                    проекта, а также предложить
                    способы привлечения ресурсов (в том числе финансовых) на реализацию проекта.
                </div>
            </div>
            <div className={styles.right_column}>
                <div className={styles.text_object + " " + stylesLight.text}><b style={{fontWeight: "600"}}>Что
                    важно знать
                    заказчику?</b></div>
                <div className={styles.text_object + " " + stylesLight.text}>Проекты, представленные на данном
                    сайте, как правило, будут
                    выполняться студентами 1 курса. Ни студенты, ни преподаватели не могут гарантировать
                    однозначный успех в реализации проекта. Вбрасывание проектной идеи – рискованный шаг,
                    который, однако, может принести бенефиты.
                </div>
            </div>
        </div>
        <div className={styles.grey}>
            <div className={styles.customer_inf}>
                <div className={styles.left_column}>
                    <div className={styles.text_object + " " + stylesLight.text}><b style={{fontWeight: "600"}}>Куратор/консультант
                        в
                        предметной области</b> – это человек, который может/готов сопровождать и/или
                        консультировать команду на ее творческом пути.
                    </div>
                    <div className={styles.text_object + " " + stylesLight.text}>Он, как правило, является
                        специалистом в области: знает
                        понятийную базу, методы, технологии, может проконсультировать и дать совет.
                    </div>
                    <div className={styles.text_object + " " + stylesLight.text}>Однако куратор не должен писать
                        для каждого из
                        участников пошаговую инструкцию с точным описанием конкретных действий.
                    </div>
                </div>
                <div className={styles.right_column}>
                    <div className={styles.text_object + " " + stylesLight.text}><b style={{fontWeight: "600"}}>Почему
                        куратор не
                        определит участникам последовательность действий и четкий план?</b></div>
                    <div className={styles.text_object + " " + stylesLight.text}>Все мы живем в динамично
                        меняющемся мире. Для того,
                        чтобы быть в нем успешными, нужно уметь действовать в условиях неопределенности.
                        Научиться этому можно только на практике.
                    </div>
                    <div className={styles.text_object + " " + stylesLight.text}>Для нас важно, чтобы участники
                        команд сами искали свой
                        путь: строили гипотезы, проводили эксперименты, ошибались или достигали
                        запланированного, анализировали результаты, рефлексировали. Это опыт участников и именно
                        им нужно его прожить.
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.customer_inf}>
            <div className={styles.left_column}>
                <div className={styles.text_object + " " + stylesLight.text}>Как правило, <b
                    style={{fontWeight: "600"}}>участниками</b> проектов являются студенты НГУ. При этом
                    междисциплинарные проекты и проекты, реализуемые студентами и выпускниками разных
                    факультетов, категорически приветствуются. При реализации комплексных проектов каждому
                    участнику важно понимать детали проекта как целого.
                </div>
            </div>
        </div>
    </>
}
