import { useEffect, useState } from "react";
import { api_connector } from "redux/api/UserApi";
import { ProjectType } from "types/ProjectTypes";

export const useProjectByDate = (projectId: string, date: string) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [project, setProject] = useState<Nullable<ProjectType>>(null);

    useEffect(() => {
        api_connector
            .getProjectByDate(projectId, date)
            .then(setProject)
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [setLoading, setError, setProject, date, projectId]);

    return { loading, error, project } as const;
};
