import React from "react";
import styles from "./SubmitProjectForm.module.css"
import {Field, FieldArray, InjectedFormProps, reduxForm} from "redux-form";
import {MultiInputFile, MultiInputFileType} from "../../inputs/MultiInputFile";
import {InputTextArea} from "../../inputs/inputTextArea";
import {Button} from "../../uiKit/btns/Button";
import stylesLight from "../../../stylesLight.module.css"
import {OwnPropsType} from "../../inputs/MultiInputSimple";

export type SubmitProjectThirdFieldsType = {
    files?: Array<MultiInputFileType>
    comment?: string,
}

function SubmitProjectThirdPreRedux(props: InjectedFormProps<SubmitProjectThirdFieldsType>) {
    return (
            <form onSubmit={props.handleSubmit} className={styles.formContainer}>
                <div className={styles.itemsContainer}>
                    <div className={styles.title + " " + stylesLight.subtitle}>
                        Добавить материалы проекта
                    </div>
                    <div className={styles.subTitle + " " + stylesLight.doit}>
                        Если у вас есть какие-либо файлы или ссылки, которые вы можете прикрепить, то сделайте это ниже:
                    </div>
                    <div className={stylesLight.doit}>
                        <FieldArray<OwnPropsType, MultiInputFileType> name="files" component={MultiInputFile}
                                                                      name_suffix={"files"}
                                                                      isTutor={false}
                                                                      label='Добавление файлов и ссылок'
                                                                      placeholderForAddBtn={'Добавить требование к участникам'}/>
                    </div>
                    <div className={styles.subTitle + " " + stylesLight.doit}>
                        Комментарий для модератора:
                    </div>
                    <div className={stylesLight.doit}>
                        <Field type="textarea" component={InputTextArea} name='comment' label='Комментарий'
                               stylecomp={true}/>
                    </div>
                </div>

                <Button type="submit" className={styles.lstbtn} style={{width: 'auto'}}>
                    <div className={stylesLight.button_text}> Отправить проект на модерацию</div>
                </Button>
            </form>
    )
}

export const SubmitProjectThirdForm = reduxForm<SubmitProjectThirdFieldsType>({
    form: 'customer-submit-project',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(SubmitProjectThirdPreRedux)
