import React from "react";
import styles from './Footer.module.css'
import {connect} from "react-redux";
import {CustomLink} from 'components/uiKit/links/CustomLink'
import stylesLight from "stylesLight.module.css"

function FooterPre() {
    return (
        <footer>
            <div className={styles.footer}>
                <div className={styles.block}>
                    <div className={styles.number + " " + stylesLight.doit}>Если у вас возникли проблемы при
                        использовании сайта, можете
                        обратиться за помощью
                        в {" "}<CustomLink
                            link={'https://t.me/joinchat/WE1vlrLuweg0YzYy'}>Telegram-чат</CustomLink>.
                    </div>
                    <div className={styles.text + " " + stylesLight.doit}>Сайт разработан командой <CustomLink
                        link={'https://education.nsu.ru/engineering_school/'}>Инженерной школы НГУ</CustomLink> при
                        поддержке Математического центра в
                        Академгородке, соглашение с Министерством науки и высшего образования Российской Федерации №
                        075-15-2019-1675.
                    </div>
                </div>
            </div>
        </footer>

    )
}

const mapStateToProps = () => ({})

export const Footer = connect(mapStateToProps)(FooterPre)
