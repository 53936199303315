import React from 'react';
import styles from './TextBlock.module.css'
import stylesLight from "../../stylesLight.module.css"

type PropsType = {
    title: string
    description: string
}

export const TextBlock: React.FC<PropsType> = ({title, description}) => {
    return (
            <div className={styles.block}>
                <div className={styles.block_title + " " + stylesLight.subtitle}>{title}</div>
                <div className={styles.block_description + " " + stylesLight.text}>{description}</div>
            </div>
    )
}
