import React, {useEffect, useState} from "react";
import {api_connector} from "../../../../../redux/api/UserApi";
import {Loader} from "../../../../../components/uiKit/Loader";
import {uniqueIdObjects} from "../../../../../features/utils";
import {ProjectsInfo} from "./ProjectsInfo";
import {TeacherStudentType} from "../../../../../types/UserTypes";
import {ProjectMiniType} from "../../../../../types/ProjectTypes";
// import { ProjectsInfo } from "./ProjectsInfo";

const type = "project"

export type ProjectTypeInDivision = ProjectMiniType & {
    users: Array<TeacherStudentType>
}

export function DivisionByProject() {
    const [projects, setProjects] = useState<Array<ProjectTypeInDivision>>([])

    useEffect(() => {
                api_connector.getStudentsAllInfo(type).then(d => {
                    const arr = d.filter(v => v.projects);
                    const uniqueArray = uniqueIdObjects(arr.map(u => u.projects).flat())
                    const projects = uniqueArray.map(p => ({
                        ...p,
                        users: arr.filter(u => (u.projects?.filter(pu => pu.id === p.id) ?? []).length > 0)
                    }))
                    setProjects(projects);

                })
            }, []
    )

    if (!(projects.length > 0))
        return (<Loader/>)
    return (<>
        {projects.map(p => <ProjectsInfo key={p.id} project={p}/>)}
    </>)

}
