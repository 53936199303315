import React from "react";
import styles from './Header.module.css'
import {connect} from "react-redux";
import logo from "../../icons/logo_mmf_optimized.svg"
import {NavLink, useHistory} from "react-router-dom";
import {HeaderBtn} from "../uiKit/btns/headerBtn";
import {HeaderMenu} from "./HeaderMenu";
import DropdownMenu from "./DropdownMenu";
import LeftBar from "../../screens/Cabinet/LeftBar";
import {AppState} from "../../redux/store";
import {UserStateType} from "../../redux/reducers/userReducer";

const HeaderLinks = [
    {
        name: "О нас",
        link: "/about"
    },
    {
        name: "Проекты",
        link: "/"
    },
    {
        name: "Бездна",
        link: "/bezdna"
    },
    {
        name: "Архив",
        link: "/archive"
    },
    {
        name: "Справочная информация",
        link: "/reference_information"
    },
    {
        name: "Заказчику",
        link: "/for_customers"
    },
]

type MapStateToPropsType = {
    user: UserStateType
}

function HeaderPre(props: MapStateToPropsType) {
    const user = props.user;
    const history = useHistory()
    return (
            <header>
                <div className={styles.header}>
                    <div className={styles.mobile}>
                        {!user.isLoggedIn && <div className={styles.logo_mobile}>
                            <NavLink to="/" style={{maxHeight: "4rem"}}>
                                <img src={logo} alt={"ММФ НГУ"}/>
                            </NavLink>
                    </div>}
                    {user.isLoggedIn && <div className={styles.mobileBar}>
                        <LeftBar/>
                    </div>}
                </div>
                <div className={styles.computer}>
                    <div className={styles.logo}>
                        <NavLink to="/" style={{maxHeight: "4rem"}}>
                            <img src={logo} alt={"ММФ НГУ"}/>
                        </NavLink>
                    </div>
                </div>
                <div className={styles.menu_button}>
                    <div className={styles.menu}>
                        {HeaderLinks.map((elem, idx) =>
                            <NavLink key={idx} exact to={elem.link} activeClassName={styles.clickd}>
                                {elem.name}
                            </NavLink>
                        )}
                    </div>
                    <div className={styles.drop_menu}>
                        <DropdownMenu/>
                    </div>
                    <div className={styles.login}>

                        {!user.user && <div>
                            {/*<button onClick={() => history.push('/auth/login_type')} className={styles.small_btn}>*/}
                            {/*    Стать заказчиком*/}
                            {/*</button>*/}
                            <HeaderBtn onClick={() => history.push('/auth/login_type')}/>
                        </div>}
                        {user.user && <div>
                            {/*<button onClick={() => history.push('/cabinet/customer/info')} className={styles.small_btn}>*/}
                            {/*    Стать заказчиком*/}
                            {/*</button>*/}
                            <HeaderMenu user={props.user.user}/>
                        </div>}
                    </div>
                </div>
            </div>
        </header>

    )
}

const mapStateToProps = (state: AppState) => ({
    user: state.user,
})

export const Header = connect(mapStateToProps)(HeaderPre)
