const changeReasonMap: Record<string, string> = {
    name: "Название",
    description: "Описание",
    problem_needed: "Проблема/потребность",
    goal: "Цель",
    use_contour: "Контур употребления",
    comment: "Комментарий",
    min_members: "Мин. количество участников",
    max_members: "Макс. количество участников",
    is_recruiting: "Идёт набор",
    status: "Статус",
    tag: "Тэг",
    keyword: "Ключевое слово",
    member: "Участник",
    file: "Файл",
};

const changeReasonPostfixMax: Record<string, string> = {
    added: "добавлен",
    changed: "изменён",
    removed: "удален",
};

export const formatChangeReason = (changeReason: string) => {
    if (!changeReason) return "";

    if (Object.keys(changeReasonMap).includes(changeReason)) {
        return `~ ${changeReasonMap[changeReason]}`;
    }

    const [reason, postfix] = changeReason.split("_");
    const postfixMapped = changeReasonPostfixMax[postfix];

    if (!postfixMapped) return changeReasonMap[reason] || "";

    return `~ ${changeReasonMap[reason]} ${changeReasonPostfixMax[postfix]}`;
};

export const withChangeLog = (items: ProjectHistoryItem[]): ProjectHistoryItemWithChangeLog[] =>
    items.map((item) => ({
        ...item,
        changelog: (item.history_change_reason || "").split(";").map(formatChangeReason).join("\n"),
    }));
