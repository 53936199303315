import React from "react";
import defaultStyles from './defaultPage.module.css'
import {PersonalInformationForm} from "../../../components/forms/PersonalInformation";
import {connect, useDispatch} from "react-redux";
import {setUserData} from "../../../redux/reducers/userReducer";
import {api_connector} from "../../../redux/api/UserApi";

function PersonalInformationPre({user}) {
    const dispatch = useDispatch();
    return (<div className={defaultStyles.itemsContainer}>
            <div className={defaultStyles.title}>
                Личная информация
            </div>
            <PersonalInformationForm user={user} onSubmit={val => {
                // TODO: ADD error
                console.log(val)
                return api_connector.updMe(val).then((r) => {
                    dispatch(setUserData(r.data))
                });
            }}/>
        </div>
    )
}

export const PersonalInformation = connect((state) => ({
    user: state.user?.user
}))(PersonalInformationPre);
