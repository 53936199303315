import React, {useState} from "react";
import styles from './input.module.css'
import {ImageModal} from "../modals/imagePickerModal";
import {Group} from "../../icons/Group";




export function ImageInput(props) {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className={styles.imageContainer} onClick={() => {
            if (isVisible === false)
                setIsVisible(true)
        }}>
            {!(props.input.value || props.defaultValue) && (
                <div className={styles.imageIcon}>
                    <Group/>
                    <div>Добавить фото</div>
                </div>

            )}
            {(props.input.value || props.defaultValue) && <img src={props.input.value || props.defaultValue} className={styles.userAvatar} alt={"Изображение пользователя"}/>}

            <ImageModal isOpen={isVisible === true} onClose={() => setIsVisible(false)} onSave={async (d) => {
                props.input.onBlur();
                props.input.onChange(d);
            }}/>
        </div>
    )
}
