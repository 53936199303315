import React, {useState} from "react";
import ReactModal from "react-modal";
import styles from "./Modals.module.css";
import inputStyles from "../inputs/input.module.css";
import getBaseUrl from "get-base-url"
import stylesLight from "stylesLight.module.css";

import ExitCross from "icons/ExitCross.svg"

export function LinkUploadModal(props) {
    // const [isLoading, setIsLoading] = useState(false);
    const [Link, setLink] = useState("");
    const [LinkName, setLinkName] = useState("");

    const LinkUploader = ((Link, LinkName) => props.onSave({
        type: '1',
        link: Link,
        fileName: LinkName,
        originalName: getBaseUrl(Link)
            .replace('https://', '')
            .replace('www.', '').replace('http://', '')
    }));

    const LinkCloser = (() => props.onClose());


    return <ReactModal isOpen={props.isOpen}
                       className={styles.container}
                       onRequestClose={() => props.onClose()}
                       ariaHideApp={false}
                       style={{overlay: {zIndex: 1000}}}>
        <div className={styles.content}>
            <div onClick={() => props.onClose()} className={styles.exitCross}>
                <img src={ExitCross} alt="Закрыть"/>
            </div>
            <div className={styles.contentBlock}>
                <div className={styles.title + " " + stylesLight.subtitle}>
                    Загрузка материалов проекта:
                </div>

                <div className={styles.subTitle + " " + stylesLight.text}>
                    Введите ссылку и ее название, которое будет отображаться на сайте.
                </div>
                <div className={stylesLight.doit}>
                    <input type="text" value={LinkName} onChange={(e) => setLinkName(e.target.value)}
                           className={inputStyles.simple} placeholder={"Введите название ссылки"}/>
                </div>
                <div className={stylesLight.doit}>
                    <input type="text" value={Link} onChange={(e) => setLink(e.target.value)}
                           className={inputStyles.simple} placeholder={"Введите ссылку"}/>
                </div>
                <button type="button" className={styles.btn} style={{marginTop: "2rem", marginBottom: "2rem"}}
                        disabled={!Link} onClick={() => {
                    LinkUploader(Link, LinkName)
                    LinkCloser()
                    setLink("")
                    setLinkName("")
                }}>
                    Сохранить
                </button>

            </div>


        </div>


    </ReactModal>
}

