import { Button } from "@material-ui/core";
import { FC, useCallback, useState } from "react";
import Modal from "react-modal";

import css from "./styles.module.css";
import { HistoryContainer } from "./HistoryContainer";
import { HistoryList } from "./HistoryList";

const modalStyles = {
    content: {
        margin: "auto",
        maxWidth: 600,
    },
} as const;

interface Props {
    projectId: number;
}
export const History: FC<Props> = ({ projectId }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleModal = useCallback(() => setIsModalVisible((v) => !v), [setIsModalVisible]);

    return (
        <div className={css.root}>
            <Button variant="outlined" id="history-btn" onClick={toggleModal}>
                История
            </Button>

            <Modal
                isOpen={isModalVisible}
                shouldCloseOnEsc
                shouldCloseOnOverlayClick
                onRequestClose={toggleModal}
                style={modalStyles}
            >
                <HistoryContainer projectId={projectId}>{(props) => <HistoryList {...props} />}</HistoryContainer>
            </Modal>
        </div>
    );
};
