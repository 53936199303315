import defaultStyles from "../../screens/Cabinet/screens/defaultPage.module.css";
import {Link} from "react-router-dom";
import {StatusBar} from "../bars/StatusBar";
import React, {useEffect, useState} from "react";
import {api_connector} from "../../redux/api/UserApi";
import {ProjectsListType} from "../../types/ProjectTypes";

type PropsType = {
    role: number
    error: string | JSX.Element
}

export const MyProjectsList: React.FC<PropsType> = ({role, error}) => {
    const [projects, setProjects] = useState<ProjectsListType>([]);
    useEffect(() => {
        api_connector.getMyProjects(role).then(t => setProjects(t))
    }, [role])
    return (<div className={defaultStyles.itemsContainer}>
        {projects.length && projects.map(p =>
                <Link key={p.id} to={`/project/${p.id}`} className={defaultStyles.item}>
                    <div className={defaultStyles.itemTitle}>{p.name.trim()}</div>
                    <StatusBar {...p}  />
                </Link>
        )}
        {!projects.length &&
        <div className={defaultStyles.description}>{error}</div>}
    </div>)
}
