import React from "react";
import styles from "./StepPanel.module.css"

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}


const StepPanel = (maxStep, startAt = 1) => {
    const rangeArray = range(maxStep, startAt);

    return ({step, changePage, red_numbs = []}) => {

        return <div className={styles.panel}>
            {rangeArray.map(el => <div style={{display: "flex", alignItems: "center"}}>
                    <div
                        className={styles.stage + " " + (el === step ? styles.active : "") + " " + (red_numbs.includes(el) ? styles.error : "")}
                        onClick={() => changePage(el)}>
                        {el}
                    </div>
                    {
                        el !== maxStep && <div className={styles.dot}>
                            <span/>
                            <span/>
                            <span/>
                        </div>

                    }
                </div>
            )}


        </div>;
    }


}

export const FiveStepPanel = StepPanel(5);
export const ThreeStepPanel = StepPanel(3);
