import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {api_connector} from "redux/api/UserApi";
import {UserType} from "../../types/UserTypes";


export type UserStateType = {
    user?: UserType,
    isLoading: boolean,
    isLoggedIn: boolean,
    isFirst: boolean,
    LoginErrors?: string,
    isReady: boolean
}


const initialState: UserStateType = {
    user: undefined,
    isLoading: true,
    isLoggedIn: false,
    isFirst: true,
    LoginErrors: undefined,
    isReady: false
};

export const getMeThunk = createAsyncThunk(
        'user/getMe',
        async () => {
            return await api_connector.getMe();
        }
);
export const updMeThunk = createAsyncThunk(
        'user/updMe',
        async (val) => {
            return await api_connector.updMe(val);
        }
);


export const logoutUserThunk = createAsyncThunk(
        'user/logout',
        // @ts-ignore
        async () => {
            return await api_connector.logout();
        }
);


export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        login: (state) => {
            state.isLoggedIn = true;
            state.user = undefined;
        },
        register: (state) => {
            state.isReady = true;
        },
        setUserData: (state, action) => {

            state.user = action.payload;
            // state.isReady = true;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
                // .addCase(createUserThunk.pending, (state) => {
                //     // state.isLoading = true;
                // })
                // .addCase(createUserThunk.fulfilled, (state, action) => {
                //     // state.isLoading = false;
                //     state.isReady = true;
                // })
                // .addCase(loginUserThunk.pending, (state) => {
                //     // state.isLoading = true;
                // })
                // .addCase(loginUserThunk.fulfilled, (state, action) => {
                //     // state.isLoading = false;
                //     state.isLoggedIn = true;
                //     state.user = undefined;
                //     // AUTH_API.addToken(state.accessToken, state.refreshToken);
                // })
                .addCase(getMeThunk.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(getMeThunk.fulfilled, (state, action) => {
                    state.user = action.payload;
                    state.isLoggedIn = true;
                    state.isFirst = false;
                    state.isLoading = false;
                })
                .addCase(updMeThunk.fulfilled, (state, action) => {
                    state.user = action.payload;
                    state.isLoggedIn = true;
                    // state.isFirst = false;
                    // state.isLoading = false;
                })
                .addCase(getMeThunk.rejected, (state) => {
                    state.user = undefined;
                    state.isLoggedIn = false;
                    state.isLoading = false;
                })
                .addCase(logoutUserThunk.fulfilled, (state) => {
                    state.user = undefined;
                    state.isLoggedIn = false;
                    // state.isLoading = false;
                });
    },
});

export const {login, register, setUserData} = userSlice.actions;

export default userSlice.reducer;


